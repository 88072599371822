import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faInstagram,
	faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { CStyles, IAssetURLs, IRoutes } from "../constants/constants";

export default function Footer(params) {

	return (
		<footer
			className="min-h-screen w-screen bg-slate-800 flex justify-center"
		>
			<div
				className="w-full flex flex-col"
			>
				<div
					className="w-full px-5 md:px-20 bg-slate-900"
				>
					<a
						href={IRoutes.home}
						className="w-fit h-fit max-h-20 bg-transparent 
						flex items-center justify-center"
					>
						<img
							src="/logo512.png"
							alt="Logo"
							className="max-lg:w-[60px] max-lg:h-[60px] w-[100px] h-[100px]"
						/>
					</a>
				</div>
				<div
					className="w-full h-fit flex flex-col items-center md:px-10 px-5"
				>
					<p
						className="py-5 max-md:w-full max-md:text-center max-md:text-sm sm:py-10"
					>
						Discover Zambia with ease and convenience, and make your travels
						truly unforgettable with Zed Tour
					</p>
					<div
						className="md:h-[75%] h-[80%] w-full flex max-md:flex-col flex-row justify-center md:pb-5"
					>
						<div
							className="flex max-sm:flex-col flex-col max-md:w-full w-1/2 items-center justify-center"
						>
							<div
								className="w-full max-sm:h-full flex justify-center"
							>
								<img
									src="/images/android-phone.png"
									alt="Andriod-device"
									className="w-[200px] md:w-[250px] max-sm:mt-0"
								/>
							</div>
							<a
								target="black"
								href={IAssetURLs.appLink}
								className="p-5 max-sm:w-11/12 max-md:w-2/4 w-3/4 transform capitalize bg-slate-800 
									hover:bg-slate-900 transition duration-300 ease-out flex flex-row 
									items-center justify-center gap-2 shadow-sm shadow-white border rounded-md max-sm:text-center"
							>
								<img
									src="/images/playstore-logo.png"
									alt=""
									height={50}
									width={50}
								/>
								Get the app from the Playstore
							</a>
						</div>
						<div
							className="md:border-l md:h-3/4 max-md:border-t max-md:w-3/4 max-md:mt-5 self-center"
						/>
						<div
							className="flex flex-col gap-5 max-md:w-full w-1/2 items-center justify-center max-sm:p-0 max-md:pb-10"
						>
							<form
								className="flex flex-col w-full h-full p-5 items-center justify-center gap-4"
							>
								<h2
									className="text-2xl font-semibold max-md:mb-4"
								>
									Send us an Email
								</h2>
								<input
									type="email"
									placeholder="Email"
									className={`h-10 w-11/12 sm:w-3/5 p-3 border border-slate-900 rounded-xl bg-slate-700
										flex justify-between items-center overflow-hidden
										${CStyles.transition_300} outline-none hover:border-[#ff772c] focus:border-[#ff772c]`}
									required
								/>
								<textarea
									placeholder="Message"
									className={`w-11/12 sm:w-3/5 p-3 border border-slate-900 rounded-xl bg-slate-700
										flex justify-between items-center overflow-hidden
										${CStyles.transition_300} outline-none hover:border-[#ff772c] focus:border-[#ff772c]`}
									required
									rows={6}
								/>
								<input
									type="submit"
									className="cursor-pointer rounded w-11/12 sm:w-3/5 
										border border-[#ff722c]
										bg-[#ff722c] hover:bg-white hover:text-[#ff722c] 
										transition duration-300 ease-out 
										px-5 py-3 text-white font-bold tracking-wide"
								/>
							</form>
						</div>
					</div>
					<div
						className="text-white max-sm:h-8 max-md:h-6 w-full flex items-center justify-center gap-4 py-5"
					>
						<a
							href="https://www.facebook.com/"
							target="blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon
								icon={faFacebook}
								size="2x"
								style={styles.icon}
								className="text-white hover:text-[#ff722c]"
							/>
						</a>

						<a
							href="https://twitter.com/"
							target="blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon
								icon={faXTwitter}
								size="2x"
								style={styles.icon}
								className="text-white hover:text-[#ff722c]"
							/>
						</a>

						<a
							href="https://www.instagram.com/"
							target="blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon
								icon={faInstagram}
								size="2x"
								style={styles.icon}
								className="text-white hover:text-[#ff722c]"
							/>
						</a>
					</div>
					<p
						className="text-white py-2"
					>
						&copy; 2024 zedtour. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	);
}
const styles = {
	socialIcons: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	icon: {
		// margin: "0 10px",
		transition: "color 0.3s ease-in-out",
	},
};
