import NavigationBar from "../../components/NavigationBar";
import { BannerAd, TileAd } from "../../components/adContainer";
import Footer from "../../components/footer";
import { IRoutes } from "../../constants/constants";
import { ServiceCard } from "./components/serviceCard";

export default function Services() {
	const services = [
		{
			title: "Trip Planning",
			image: "",
			body: `Revolutionize your trip planning with the ZedTour app. Effortlessly 
              create and manage your journeys, with intuitive features to 
              allocate your finances, scout for accommodation, and more. Streamline 
              the planning process and take control of every aspect of your travel 
              experience, ensuring a smooth and enjoyable adventure. With ZedTour, 
              planning your trips has never been this easy and efficient.`,
		},
		{
			title: "bookings",
			image: "",
			body: `Experience the ultimate convenience with the ZedTour app – 
              your one-stop solution for seamless accommodation booking 
              during your travels. With just a tap and swipe, unlock a 
              world of options including lodges, resorts, hotels, and more. 
              Our user-friendly app empowers you to explore and select 
              the perfect accommodation, ensuring a comfortable and enjoyable 
              stay throughout your journey. Elevate your travel experience 
              with hassle-free accommodation booking, all at your fingertips.`,
		},
		{
			title: "location discovery",
			image: "",
			body: `Embrace your adventurous spirit! Your journey begins here as 
              you explore an array of captivating destinations throughout 
              Zambia. Immerse yourself in diverse cultures, savor unique 
              cuisines, and marvel at breathtaking landscapes. Whether you 
              seek cultural encounters, culinary delights, or scenic wonders, 
              our platform is your gateway to an unforgettable travel experience 
              in Zambia. Uncover the extraordinary as you traverse the remarkable 
              tapestry of cultures, flavors, and vistas that this beautiful 
              country has to offer.`,
		},
		{
			title: "art discovery",
			image: "",
			body: `Passionate about art? Immerse yourself in a diverse collection of 
              artistic masterpieces sourced from a rich tapestry of cultures across 
              Zambia. Our user-friendly app streamlines the process of discovering 
              and acquiring exquisite pieces from a multitude of talented art 
              vendors scattered throughout the country. Elevate your art experience
               with seamless navigation and effortless purchases, all within the 
               vibrant tapestry of Zambia's artistic landscape.`,
		},
	];
	return (
		<>
			<NavigationBar pageName={IRoutes.services.substring(1)} />
			<div
				className="w-screen h-fit pt-24"
			>
				<div
					className="container mx-auto px-8 py-5"
				>
					<BannerAd />
					<div
						className="w-full min-h-96 h-fit py-5 bg-slate-700"
					>
						<div
							className="h-full w-full grid grid-cols-2 gap-5"
						>
							{services.map(
								(service, index) =>
									index % 2 === 0 ? (
										<>
											<ServiceCard
												key={index}
												service={service}
											/>
											<TileAd />
										</>
									) :
										<ServiceCard
											key={index}
											service={service}
										/>
							)
							}

						</div>
					</div>
					<BannerAd />
				</div>
			</div>
			<Footer />
		</>
	);
}
