import React, { useState } from 'react'
import { CStyles, IRoutes, FetchHeaders } from '../../constants/constants'
import NavigationBar from '../../components/NavigationBar'
import MapWithUserLocation from './components/userlocation'
import Footer from '../../components/footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Distress } from './models/distressModel'
import { useSelector } from 'react-redux'
import { faLandMineOn, faLocationArrow } from '@fortawesome/free-solid-svg-icons'


export default function FindMe(params) {
	const user = useSelector(state => state.user.user)
	const [isLoading, setIsLoading] = useState(false)
	const [userLocation, setUserLocation] = useState(null)
	const [username, setUsername] = useState(null)
	const [phone, setPhone] = useState(null)

	const sendDistressCall = () => {
		if (userLocation) {
			fetch(
				`${process.env.REACT_APP_API_URL}/emergency/findme`,
				{
					method: 'POST',
					headers: {
						"authorization": `Bearer ${user.authToken.token}`,
						...FetchHeaders
					},
					body: JSON.stringify(new Distress({
						userid: user.uid,
						geoLocation: userLocation,
						username: username,
						phone: phone
					})),
				})
				.then(response => {
					const { status } = response

					if (status === 200)
						alert('Distress send.')

				})
				.catch(error => {
					console.error('Error sending distress call:', error)
				})
		} else {
			console.error("User location not available.")
		}
	}
	return (
		<>
			<NavigationBar pageName={IRoutes.findme.substring(1)} />
			<div
				className='w-screen h-screen pt-20 md:pt-24 overflow-x-hidden'
			>
				<div
					className='container h-full mx-auto py-5 px-10 max-md:px-5 flex justify-center items-center text-center'
				>
					<div
						className='w-full h-full relative'
					>
						<MapWithUserLocation />
						<div
							className='absolute inset-y-0 right-1 w-20 h-full flex flex-col items-center gap-3 py-4 text-slate-800'
						>
							<button
								className={`bg-slate-400 w-14 h-12 p-3 rounded-full
					flex items-center justify-center 
					hover:scale-[1.1] hover:shadow-sm hover:bg-slate-100
					 ${CStyles.transition_300} font-semibold`}
								onClick={() => sendDistressCall}
							>
								<FontAwesomeIcon icon={faLandMineOn} size='' className='text-red-600 text-2xl' />
							</button>
							<button
								className={`bg-slate-400 w-14 h-12 p-3 rounded-full
					flex items-center justify-center 
					hover:scale-[1.1] hover:shadow-sm hover:bg-slate-100
					 ${CStyles.transition_300} font-semibold`}
							// onClick={() => fetchUserLocation()}
							>
								<FontAwesomeIcon icon={faLocationArrow} size='2x' />
							</button>
						</div>
						{/* <div
							className='w-full -mt-16 z-50 absolute inset-x-0 flex items-center justify-center '
						>
							<div
								className=' border-[12px] border-white rounded-full'
							>
								<div
									className='h-32 w-32 bg-red-500 hover:bg-red-600 hover:scale-105
									transition-all duration-300 ease-in-out hover:shadow-2xl							
									flex items-center justify-center rounded-full'
								>
									<button
										className="h-28 w-28 border-4 border-white rounded-full 
											bg-red-500 hover:bg-red-600 text-white font-bold px-2"
										onClick={() => {
											fetchUserLocation()
											sendDistressCall()
										}}
									>
										Send Distress Call
									</button>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}