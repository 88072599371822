import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FetchHeaders, IRoutes, StorageKeys } from '../../../constants/constants';
import ListItem from './listItem';
import { SectionHeader } from '../../../components/section_header';
import { NothingToShow } from '../../../components/nothingtoshow';

const LoadSkeleton = () => (
	<div
		className='w-full h-full'
	>
		<div className='w-full h-16 animate-pulse rounded-2xl bg-slate-600 mb-4' />
		<div
			className='h-full w-full grid max-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-4'>
			{
				Array
					.apply(null, Array(4))
					.map(
						(_, i) => (
							<div
								key={i}
								className='h-60 animate-pulse bg-slate-600 rounded-2xl'
							/>
						)
					)
			}
		</div>
	</div>
)

export default function AttractionsComponent({ children }) {
	const user = useSelector(state => state.user.user);
	const [isLoading, setIsLoading] = useState(true);
	const [attractions, setAttractions] = useState([]);

	const categories = ['heritage sites', 'safari', 'national parks', 'monuments', 'museums', 'festivals', 'ceremonies', 'hotsprings']


	const fetchAttractions = useCallback(async () => {
		await fetch(
			`${process.env.REACT_APP_API_URL}/attractions`,
			// `http://127.0.0.1:2000/attractions`,
			{
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
			})
			.then(async results => {
				let data = await results.json();

				data = data.data;

				if (!data) {
					return console.log("No Data");
				}

				console.log('Data fetched.');

				setAttractions(data);
				window
					.localStorage
					.setItem(StorageKeys.booking + `:attractions`, JSON.stringify(data))

				setTimeout(() => setIsLoading(false), 2000);
			})
			.catch(error => {
				console.log(error);
				setTimeout(() => setIsLoading(false), 2000);
			});
	}, [user.authToken.token])

	useEffect(() => {
		fetchAttractions()
	}, [fetchAttractions, user])

	return isLoading
		? <div className='w-full h-full flex flex-col gap-5'>
			<LoadSkeleton />
			<LoadSkeleton />
		</div>
		: attractions && attractions.length === 0
			? (
				<NothingToShow />
			)
			: (
				<div
					className='flex flex-col'
				>
					{
						categories.map(
							(cat, index) => (
								<div
									key={index}
									className='w-full flex flex-col'
								>
									<SectionHeader title={cat} />
									<div
										className='min-w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 sm:p-5 px-10'
									>
										{
											attractions.map(
												(item, i) => (
													<ListItem
														key={i}
														business_id={item.business_id}
														business_name={item.business_name}
														image={item.image}
														rating={item.rating}
														location={item.location}
														href={`${IRoutes.booking + `/attractions/category/${item.category}`}?q=${encodeURIComponent(item.business_id)}`}
													/>
												)
											)
										}
									</div>
								</div>
							)
						)
					}
				</div>
			)
}