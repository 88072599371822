import NavigationBar from "../../components/NavigationBar";

export default function Error404(params) {
	return (
		<>
			<NavigationBar />
			<div
				className="flex flex-col items-center justify-center w-screen h-screen text-slate-400 gap-4"
			>
				<h1
					className="text-9xl "
				>
					404
				</h1>
				<p
					className="text-2xl"
				>
					Page Not Found.
				</p>
			</div>
		</>
	);
}
