import React from 'react';
import { CStyles, IRoutes } from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export default function SearchItemComponent({ item }) {

	return (
		<div
			className='h-60 md:h-72 rounded-2xl bg-slate-800'
			onClick={e => e.stopPropagation()}
		>
			<a
				href={`${IRoutes.booking + '/accommodations/type/' + item.type.toLowerCase()}?q=${encodeURIComponent(item.business_id)}`}
				className={`md:min-w-full max-h-56 flex flex-col gap-1 hover:bg-slate-600 
					${CStyles.transition_300} rounded-t-xl`}
			>
				<div
					className='w-full h-36 md:h-56'
				>
					<img
						alt={item.business_name}
						className={`object-cover rounded-t-xl w-full h-36 md:h-56
								hover:border-[#ff722c]	${CStyles.transition_300}`}
						loading='lazy'
						src={`${item.image}`}
						srcSet={`${item.image}?tr=w-400 400w, ${item.image}?tr=w-800 800w`}
					/>
				</div>
				<div
					className='w-full h-full flex flex-col ps-1'
				>
					<h2
						className={`capitalize md:text-lg font-semibold 
							hover:text-[#ff722c] flex justify-between
							${CStyles.transition_300} pe-5`
						}
					>
						{item.business_name}
						<span
							className='md:text-xl font-bold flex sm:items-center gap-2'
						>
							<FontAwesomeIcon
								icon={faStar}
								className='md:text-md max-sm:pt-1 text-orange-600' />
							{item.rating}
						</span>
					</h2>

					<p
						className='text-sm text-slate-500 capitalize'
					>
						{item.location.town},{" "}
						{item.location.province}
					</p>
				</div>
			</a>

			{/* <GridCard imageURL={item.imageURL} title={item.hotel_name} subtitle={item.loading}/> */}
		</div>
	);
}