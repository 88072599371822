import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: "zed-tour-web.firebaseapp.com",
	projectId: "zed-tour-web",
	storageBucket: "zed-tour-web.appspot.com",
	messagingSenderId: "420358096382",
	appId: "1:420358096382:web:42e8256004a8665f4b2caf",
	measurementId: "G-W8R9SNQ796"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
