import React, { useRef } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ScrollableListContainer({ title, subHeading, children }) {
	const scrollViewRef = useRef();

	const Scroll = (direction) => {
		if (direction === 'left')
			scrollViewRef.current.scrollBy({ left: -260, behavior: "smooth" });
		else
			scrollViewRef.current.scrollBy({ left: 260, behavior: "smooth" });

	}

	return (
		<div
			className='w-full flex flex-col gap-4'
		>
			<div
				className='w-full'
			>
				<h1
					className='text-2xl font-semibold capitalize'
				>
					{title}
				</h1>
				{
					subHeading
					&&
					<p
						className='text-sm text-slate-200'
					>
						{subHeading}
					</p>}
			</div>
			<div
				className='w-full h-72 relative flex items-center'
			>
				<div
					className='w-[113%] sm:w-[108%] md:w-[104%] h-0 -ml-5 absolute flex justify-between items-center'
				>
					<div
						className='w-14 h-14 bg-slate-800 rounded-full 
							flex justify-center items-center text-xl 
							shadow-md cursor-pointer hover:text-[#ff722c]'
						onClick={() => Scroll('left')}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
					</div>
					<div
						className='w-14 h-14 bg-slate-800 rounded-full 
							flex justify-center items-center text-xl 
							shadow-md cursor-pointer hover:text-[#ff722c]'
						onClick={() => Scroll('right')}
					>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				</div>
				<div
					className='w-full h-full flex gap-5 px-1 overflow-y-hidden md:overflow-x-hidden'
					ref={scrollViewRef}
				>
					{children}
				</div>
			</div>
		</div>
	);
}