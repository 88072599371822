import { useState, useEffect } from "react";
import { SectionHeader } from "../../../components/section_header";

export default function Testimonials() {
	const reviews = [
		{
			name: "Jackson Banda",
			imageUrl: `/images/dp_placeholder.jpg`,
			review: `It's a very great app.`,
		},
		{
			name: "Peter Mwape",
			imageUrl:
				"/images/dp_placeholder.jpg",
			review: `It's user friendly, very convenient, and overrall an awesome up.`,
		},
		{
			name: "Veronica Chisenga",
			imageUrl:
				"/images/dp_placeholder.jpg",
			review: `Best travel app I've used so far. It has everything you need; trip planner, financial tip you name it.`,
		},
	];

	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		clearTimeout();
		setTimeout(() => {
			currentIndex > 0 ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1);
		}, 5000);
	}, [currentIndex]);

	return (
		<div id='' className="w-full h-fit sm:h-[200vh] md:h-fit bg-slate-700">
			<div
				className="w-full h-full container mx-auto py-5 px-10 max-md:px-5"
			>
				<SectionHeader title={'What our clients say about us?'} />
				<div
					className="w-full py-5 flex flex-col gap-5"
				>
					{/* <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl capitalize">trending</h2> */}
					<div className="w-full h-80 bg-slate-600 py-3">

					</div>

					<div className="w-full h-40 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
						<div className="w-full h-full bg-slate-600"></div>
						<div className="w-full h-full bg-slate-600"></div>
						<div className="w-full h-full bg-slate-600"></div>
						<div className="w-full h-full bg-slate-600"></div>
					</div>
				</div>
			</div>
		</div>
	);
}
