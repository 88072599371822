import React from 'react';
import { CStyles, IRoutes } from '../../../constants/constants';
import { useNavigate } from 'react-router-dom'

const EventCard = ({ event }) => {
	const navigate = useNavigate()

	return (
		<div
			className={`h-60 bg-slate-800 rounded-lg shadow-md overflow-hidden relative
				hover:scale-[1.02] ${CStyles.transition_300} cursor-pointer`}
			onClick={() => navigate(IRoutes.events + '/' + event.id)}
		>
			<img
				src={event.image}
				alt={event.title}
				className='w-full h-2/3 object-cover object-center bg-slate-500'
			/>
			<h2
				className='text-lg font-bold mb-2 absolute inset-y-1/2 px-4'
			>
				{event.title.length > 20 ? event.title.substr(0, 20) + '...' : event.title}
			</h2>
			<div
				className='h-1/2 px-4 w-full flex flex-col py-4'
			>
				<p
					className='text-slate-300 capitalize'
				>
					{event.location.town}, {event.location.province}
				</p>
				<p
					className='text-slate-300'
				>
					Date: {event.date.split('T')[0]}
				</p>
			</div>
		</div>
	);
}

export { EventCard }