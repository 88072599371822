import React from 'react';

const EventDetail = ({ event }) => {
	return (
		<div>
			<h1>{event.event_title}</h1>
		</div>
	);
}

export { EventDetail }