/**
 * @param {string} attraction_id
 * @param {string} attraction_name
 * @param {string} category
 * @param {string} email
 * @param {string} phone
 * @param {string} image
 * @param {string} about
 * @param {Object} location 
 * @param {Number} rating
*/

import { LocationModel } from "../../../models/locationModel";

class Attraction {
	constructor({
		business_id,
		business_name,
		category,
		type,
		email,
		phone,
		location,
		image,
		about,
		rating
	}) {
		this.business_id = business_id ?? '';
		this.business_name = business_name ?? '';
		this.category = category ?? '';
		this.type = type ?? '';
		this.email = email ?? '';
		this.phone = phone ?? ''
		this.location = location ?? new LocationModel();
		this.image = image ?? '';
		this.about = about ?? '';
		this.rating = rating ?? 0;
	}
}

export { Attraction };