import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from '@fortawesome/free-solid-svg-icons'

import NavigationBar from '../../components/NavigationBar'
import { CStyles, FetchHeaders, IRoutes } from '../../constants/constants'
import { BookingDetails } from './components/accommodation/bookingdetails'
import { NothingToShow } from '../../components/nothingtoshow'

export default function Dashboard(params) {
	const user = useSelector(state => state.user.user)
	const { type, id } = useParams()
	const [dashboardQuery] = useSearchParams()
	const navigate = useNavigate()

	const [bookings, setBookings] = useState([])
	const [pending, setPending] = useState([])
	const [accepted, setAccepted] = useState([])
	const [rejected, setRejected] = useState([])
	const [details, setDetails] = useState();
	const [viewData, setViewData] = useState();


	const fetchBookings = useCallback(async () => {
		if (!user.roles || !user.business_id)
			return

		await fetch(
			`${process.env.REACT_APP_API_URL}/bookings/${user.roles}/${user.business_id}`,
			{
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				}
			})
			.then(async response => {
				const data = await response.json()

				console.log('data fetched')

				if (!data.data) return

				setBookings(data.data)
				setPending(data.data.filter((item) => item.status === 'pending'))
				setAccepted(data.data.filter((item) => item.status === 'accepted'))
				setRejected(data.data.filter((item) => item.status === 'rejected'))
			})
			.catch(error => console.error(error))
	}, [user])

	useEffect(() => {
		fetchBookings()
	}, [user, fetchBookings])

	useEffect(() => {
		const query = dashboardQuery.get('')

		if (!query) return

		const [booking] = pending.filter((item) => item.booking_id === query)

		setDetails(booking)

	}, [pending, dashboardQuery])

	useEffect(() => {
		if (user.uid === '') navigate(IRoutes.home)
	}, [navigate, user])

	// Check acc service type
	let accommodationOptions = [
		{
			title: 'Bookings',
			options: [
				{
					title: 'successful',
					count: 0,
					action: () => {
						// setViewData()
						navigate(IRoutes.dashboard + '/bookings/successful')
					}
				},
				{
					title: 'pending',
					count: pending.length,
					action: () => {
						setViewData(pending)
						navigate(IRoutes.dashboard + '/bookings/pending')
					}
				},
				{
					title: 'accepted',
					count: accepted.length,
					action: () => {
						setViewData(accepted)
						navigate(IRoutes.dashboard + '/bookings/accepted')
					}
				},
				{
					title: 'rejected',
					count: rejected.length,
					action: () => {
						setViewData(rejected)
						navigate(IRoutes.dashboard + '/bookings/rejected')
					}
				},
				{
					title: 'total',
					count: bookings.length,
					action: () => {
						setViewData(bookings)
						navigate(IRoutes.dashboard + '/bookings/total')
					}
				}
			]
		}
	]

	return (
		<>
			<NavigationBar pageName={'dashboard'} />
			<div
				className='w-screen min-h-screen pt-20 md:pt-24 overflow-x-hidden'
			>
				<div
					className='container h-full mx-auto py-10 px-9 max-md:px-5 flex flex-col'
				>
					<div
						className='w-full mb-10 flex justify-between'
					>
						<h1
							className='text-6xl flex gap-5'
						>
							{user.username}
						</h1>
						<h1
							className='text-4xl flex gap-5 items-center'
						>
							<FontAwesomeIcon icon={faStar} className='text-2xl text-orange-500' />
							{user.rating}2.5/5
						</h1>
					</div>
					{
						dashboardQuery.get('') && details ?
							(
								<BookingDetails booking_data={details} dashboardQuery={dashboardQuery} />
							)
							:
							type || id ?
								(
									<div
										className='w-full h-fit max-h-screen p-5 rounded-2xl bg-slate-800 shadow'
									>
										<div
											className='w-full flex justify-between'
										>
											<h2
												className='text-lg text-slate-400 font-semibold capitalize'
											>
												{id} {type}
											</h2>
										</div>

										<hr className='w-full my-2' />

										{viewData.length > 0 ?
											<table className='w-full border'>
												<thead
													className={`h-14 bg-slate-600 border capitalize`}
												>
													<th
														className='border-e w-14 border-slate-500'
													>
														No.
													</th>
													<th
														className='border-e border-slate-500 w-80'
													>
														customer
													</th>
													<th
														className='border-e border-slate-500'
													>
														room(s)
													</th>
													<th
														className='border-e border-slate-500'
													>
														check-in
													</th>
													<th
														className='border-e border-slate-500'
													>
														check-out
													</th>
													<th>
														status
													</th>
												</thead>
												<tbody>
													{
														viewData.map((item, i) => (
															<tr
																key={i}
																className='text-center h-10 cursor-pointer hover:bg-slate-500 transition-all duration-300
																	ease-in-out border-b'
																onClick={
																	() =>
																		navigate(IRoutes.dashboard + `/${type}/${id}?=${encodeURIComponent(item.booking_id)}`)
																}
															>
																<td
																	className="border-e bg-transparent"
																>
																	{i + 1}
																</td>
																<td
																	className="border-e"
																>
																	{item.name}
																</td>
																<td
																	className="border-e"
																>
																	{item.rooms.map((room, index) => (
																		<p
																			key={index}
																			className={`${item.rooms.length > 1 && 'me-2'}`}
																		>
																			{room.roomClass}{item.rooms.length > 1 && index < item.rooms.length - 1 && ','}
																		</p>
																	))}
																</td>
																<td
																	className="border-e"
																>
																	{item.check_in.substring(0, 10)}
																</td>
																<td
																	className="border-e"
																>
																	{item.check_out.substring(0, 10)}
																</td>
																<td
																>
																	{item.status}
																</td>
															</tr>
														))
													}

												</tbody>
											</table>
											:
											<NothingToShow />
										}

									</div>
								)
								:
								(
									<div
										className='min-h-96 grid grid-cols-3 gap-3'
									>
										<div
											className='col-span-2 bg-slate-800 p-5 rounded-2xl shadow'
										>
											<h2
												className='text-lg text-slate-400 font-semibold'
											>
												Bookings
											</h2>
											<hr className='w-full my-2' />

											<div
												className='w-full h-auto grid grid-rows-3 grid-cols-2 gap-2'
											>
												{accommodationOptions[0].options.map((option, i) => (
													<div
														key={i}
														className={`min-h-24 p-2 bg-slate-600 flex flex-col items-center justify-center rounded-md cursor-pointer 
															hover:text-[#ff722c] hover:scale-[1.01] hover:shadow ${CStyles.transition_300}
															${i === accommodationOptions[0].options.length - 1 && 'col-span-2'}`}
														onClick={() => option.action()}
													>
														<p className='font-bold capitalize text-lg'>{option.title}</p>
														<p>{option.count || 'N/A'}</p>
													</div>
												))}
											</div>
										</div>
										<div
											className='h-full bg-slate-800 p-5 rounded-2xl shadow'
										>
											<h2
												className='text-lg text-slate-400 font-semibold'
											>
												Reviews
											</h2>
											<hr className='w-full my-2' />
											<div
												className='w-full h-52 grid grid-rows-2 gap-2'
											>
												<div
													className='p-2 bg-slate-600 flex flex-col items-center justify-center rounded-2xl cursor-pointer'
												>
													<p className='font-bold capitalize text-lg'>Total Bookings</p>
													<p>N/A</p>

												</div>
												<div
													className='p-2 bg-slate-600 flex flex-col items-center justify-center rounded-2xl cursor-pointer'
												>
													<p className='font-bold capitalize text-lg'>Total Bookings</p>
													<p>N/A</p>

												</div>
											</div>
										</div>
									</div>
								)
					}

					{/* </div> */}
					{/* <h1>Dashboard</h1> */}
					{/* *For accommodations*
					
					TODO: reviews
					TODO: - show highest rating
					TODO: - show lowest rating
					TODO: messages
					TODO: - show unread count
					TODO: - show newest messages (3)
					ads
					- ? 
					 */}

				</div>
			</div >
		</>
	)
}