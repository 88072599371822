const IRoutes = Object.freeze({
	home: '/',
	dashboard: '/dashboard',
	events: '/events',
	booking: '/booking',
	tours: '/tours',
	findme: '/findme',
	services: '/services',
	contact: '/contact',
	FAQ: '/faq',

});

const IAuthRoutes = Object.freeze({
	user: '/user',
})
const IOtherRoutes = Object.freeze({
	profile: '/profile',
})

const IAssetURLs = Object.freeze({
	imagekitBucket: 'https://ik.imagekit.io/uz4u6crdje/zedtour',
	appLink: 'https://play.google.com/store/apps/details?id=com.euler.zed.zed_tour_mobile'
})

const FetchHeaders = Object.freeze({
	"Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL}`,
	"Access-Control-Allow-Headers": 'access-control-allow-origin, Content-Type',
	"Content-Type": "application/json"
})

const StorageKeys = Object.freeze({
	user: 'zedtour:cache:user',
	booking: 'zedtour:cache:booking',
	attraction: 'zedtour:cache:attraction'
})

const CStyles = Object.freeze({
	transition_300: 'transition-all duration-300 ease-in-out'
})

export {
	IRoutes,
	IAssetURLs,
	IAuthRoutes,
	IOtherRoutes,
	FetchHeaders,
	StorageKeys,
	CStyles
};