import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet';


const MapWithUserLocation = () => {
	const [userLocation, setUserLocation] = useState(null)

	const UserIcon = new Icon(
		{
			iconUrl: './images/exercising.png',
			iconSize: [40, 40]
		}
	)
	const UserLocation = () => {
		useEffect(() => {
			// Get user's current location
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setUserLocation({ lat: latitude, lng: longitude });
				},
				(error) => {
					console.error('Error getting user location:', error);
				}
			);
		}, []);

		return (
			<>
				{userLocation && (
					<Marker
						position={[userLocation.lat, userLocation.lng]}
						icon={UserIcon}
					>
						<Popup>You are here</Popup>
					</Marker>
				)}
			</>
		);
	}

	const fetchUserLocation = useCallback(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setUserLocation({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				})

			}, (error) => {
				console.error("Error getting user location:", error)
			})
		} else {
			console.error("Geolocation is not supported by this browser.")
		}
	}, [])


	useEffect(() => {
		fetchUserLocation()
	}, [fetchUserLocation])

	return (
		<MapContainer
			center={userLocation ?? [-15.4206208, 28.311552]}
			zoom={13}
			className='w-full h-full border border-slate-600 rounded-md p-1 overflow-hidden z-0'
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				className='-z-10'
			/>
			<UserLocation />

		</MapContainer>
	);
}

export default MapWithUserLocation;
