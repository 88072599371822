import React from 'react';

export default function Spinner(params) {

	return (
		<div
			className="h-screen w-screen absolute flex justify-center items-center z-50 bg-slate-800"
		>
			<div className='w-24 h-24 bg-[#ff722c] rounded-full animate-pulse' />
		</div>
	);
}