import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Amenity({ isActive, onClick, text, icon }) {

	return (
		<div
			className={`h-10 min-w-24 px-2 border rounded-2xl 
				${isActive && `text-[#ff722c] border-[#ff722c]`}
				flex justify-center items-center gap-2 
				transition duration-300 ease-in
				hover:border-[#ff722c] hover:text-[#ff722c] hover:cursor-pointer												
				`
			}
			onClick={onClick}

		>
			{
				icon && <FontAwesomeIcon icon={icon} />
			}
			<p className='capitalize'>
				{text}
			</p>
		</div>
	);
}