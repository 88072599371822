
const SectionHeader = ({ title, onClickViewAll }) => {
	return (
		<>
			<div
				className="w-full h-14 flex items-center justify-between"
			>
				<h1
					className="text-3xl font-bold tracking-wide text-blue-50 sm:text-4xl capitalize"
				>
					{title}
				</h1>
				{onClickViewAll && (
					<p
						className="text-[#ff722c] cursor-pointer hover:underline transition-all duration-300 ease-in-out
					text-lg"
						onClick={onClickViewAll}
					>
						View all
					</p>
				)}
			</div>
			<hr className="w-full mt-2 bg-white" />
		</>
	)
}

export { SectionHeader }