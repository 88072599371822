import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		user: {
			uid: '',
			username: '',
			email: '',
			roles: [],
			imageurl: '',
			business_id: '',
			date_created: '',
			authToken: {
				createdOn: '',
				expiresIn: '',
				token: ''
			},
			refreshToken: {
				createdOn: '',
				token: ''
			}
		}
	},
	reducers: {
		updateUser: (state, action) => {
			state.user = {
				...state.user,
				...action.payload
			}
		}
	}
})

export const { updateUser } = userSlice.actions

export default userSlice.reducer