import React, { useCallback, useEffect, useState } from 'react';
import { FetchHeaders, IRoutes, StorageKeys } from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faStar } from '@fortawesome/free-solid-svg-icons';
import { LocationModel } from '../../../models/locationModel';
import { ItemCard } from '../../../components/cards';

export default function BoookingListings({ column, category }) {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [filter, setFilter] = useState('');
	const [filterType, setFilterType] = useState('')
	const [filters, setFilters] = useState([]);
	const [isAddFilter, setIsAddFilter] = useState(false);

	const filterHandler = () => {
		if (!filterType || !filter) {
			setIsAddFilter(false);
			return;
		}
		// else if (filters.in)
		setFilters([{ filter: filterType, value: filterType === 'location' ? new LocationModel({ province: filter }) : filter }]);
		console.log(filters)
		setFilter('');
		setFilterType('');
		setIsAddFilter(false);
	}

	useEffect(() => {
		const getFiltered = async () => {
			setIsLoading(true);
			setData(prev => [...prev.filter((item, index) => item[filterType] === filter)])
		}

	}, [filters])

	const getPageCache = useCallback(async () => {
		if (data && data.length > 0)
			return;

		let pageData = window
			.localStorage
			.getItem(StorageKeys.booking + `:${category}`);

		if (!pageData) {
			setIsLoading(false);
			return;
		}

		pageData = JSON.parse(pageData);

		setData(pageData.data)
	}, [data, category])

	useEffect(() => {
		getPageCache();
	}, [getPageCache]);

	useEffect(() => {
		const getPageData = async () => {
			setIsLoading(true);
			let user = window.localStorage.getItem(StorageKeys.user);

			if (!user) return false;

			user = JSON.parse(user);

			await fetch(
				`${process.env.REACT_APP_API_URL}/accommodations/${column}/${category}`,
				{
					method: 'GET',
					headers: {
						"authorization": `Bearer ${user.authToken.token}`,
						...FetchHeaders
					},
				})
				.then(async res => {
					const resData = await res.json();

					if (!resData) return;

					console.log('Data fetched.');

					setData(resData.data);

					window
						.localStorage
						.setItem(
							StorageKeys.booking + `:${category}`,
							JSON.stringify(resData)
						)

					setTimeout(() => setIsLoading(false), 2000);
					return true;
				})
				.catch(error => {
					console.log(error);
					getPageCache();
					setTimeout(() => setIsLoading(false), 2000);
					return false;
				});
		};

		getPageData();
	}, [])

	return (
		<>
			{isLoading
				? <>
					<div className='min-h-14 w-full bg-slate-800 animate-pulse rounded-2xl' />
					<div
						className='min-w-full h-full grid max-sm:grid-cols-1 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-2'
					>
						{Array.apply(null, Array(8)).map((_, i) => (
							<div
								key={i}
								className='h-56 animate-pulse bg-slate-800 rounded-2xl'
							/>
						))}
					</div>
				</>
				: data && data.length === 0
					? (
						<div className='w-full min-h-full flex items-center justify-center'>
							<h1 className='text-5xl font-semibold text-center'>
								Nothing to show.
							</h1>
						</div>
					)
					: <>
						<div
							className='py-5 px-2 w-full h-fit flex items-center bg-slate-800 rounded-2xl'
						>
							<div
								className='w-full flex flex-wrap md:items-center gap-5'
							>
								<h1
									className='text-2xl font-semibold capitalize text-nowrap md:w-50 px-5'
								>
									Viewing {column === 'location' ? category : category + 's'}
								</h1>
								{/* <div className='flex items-center gap-2'>
									<p className=''>filters</p>
									<div className='h-full flex gap-2'>
										<div
											className='max-w-12 w-12 h-full flex justify-center items-center'
											onClick={e => {
												e.stopPropagation();
											}}
										>
											<div className='w-full h-8 flex justify-center items-center 
												border border-slate-600 rounded-2xl hover:border-[#ff722c] hover:text-[#ff722c]'
												onClick={e => {
													setIsAddFilter(true);
												}}
											>
												<FontAwesomeIcon icon={faAdd} />

											</div>
											{isAddFilter &&
												<div
													className='absolute w-60 h-40 p-3 bg-slate-200 transform translate-y-28
														max-sm:-translate-x-16
											flex flex-col justify-between items-center rounded-2xl shadow-lg'
												>
													<div className='flex flex-col gap-3 w-full'>
														<select
															className='w-full h-8 rounded-2xl outline-none text-center'
															value={filterType}
															onChange={e => {
																setFilterType(e.target.value);
																setFilter('');
															}}
														>
															<option value={''}>filter</option>
															<option value={'location'}>Location</option>
															<option value={'rating'}>Rating</option>
														</select>
														<input
															type={filterType === 'rating' && 'number'}
															value={filter}
															max={filterType === 'rating' && 5}
															min={filterType === 'rating' && 1}
															onChange={e => {
																let value = e.target.value;

																if (filterType === 'rating')
																	value = value < 1 ? null : value > 5 ? 5 : value

																setFilter(value);
															}}
															className='w-full h-8 rounded-2xl outline-none text-center'
														/>
													</div>
													<button
														type='button'
														className='w-full h-10 flex items-center justify-center 
															rounded-2xl bg-[#ff722c]'
														onClick={filterHandler}
													>
														Done
													</button>
												</div>
											}
										</div>
									</div>
								</div> */}
								<div
									className='flex gap-2 h-8 md:h-full flex-wrap'
								>
									{filters.map((filter, i) =>
									(
										<div
											key={i}
											className='h-full min-w-12 px-3 flex items-center justify-center 
														bg-white rounded-2xl'
											onClick={() =>
												setFilters([...filters.filter((_, index) => index !== i)])
											}
										>
											{filter.type} - {filter.filter}
										</div>
									)
									)}
								</div>
							</div>
						</div>

						<div
							className='w-full grid max-sm:grid-cols-1 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-2'
						>
							{data && data.map(
								(item, i) => (
									<ItemCard
										item={item}
										url={`${IRoutes.booking + '/accommodations/type/' + category}?q=${encodeURIComponent(item.business_id)}`}
									/>
								)
							)}
						</div>
					</>
			}
		</>
	);
}