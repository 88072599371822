import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { StyledButton } from "../../../../components/customButton"
import { FetchHeaders } from "../../../../constants/constants"
const BookingDetails = ({ booking_data, dashboardQuery }) => {
	const user = useSelector(state => state.user.user)
	const [totalRoomCost, setTotalRoomCost] = useState(0)

	const buttonHandle = async (action) => { // action should either be 'accepted' or 'rejected'
		const data = { ...booking_data, status: action }
		console.log(data)

		await fetch(
			`${process.env.REACT_APP_API_URL}/bookings/${booking_data.booking_id}`,
			// `http://127.0.0.1:2000/bookings/${booking_data.booking_id}`,
			{
				method: 'PUT',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
				body: JSON.stringify(data)
			})
			.then(async response => {
				const res = await response.json()

				if (res.ok) console.log(res)
			})
			.catch(error => console.log(error.message))
	}

	useEffect(() => {
		let cost = 0
		booking_data.rooms.forEach((room) => (cost += Number(room.pricePerNight)))

		setTotalRoomCost(cost)
	}, [booking_data.rooms, dashboardQuery])

	return (
		<div
			className='w-full h-full pt-10 px-10 bg-slate-800 rounded-2xl shadow'
		>
			<h2
				className='text-xl font-semibold capitalize'
			>
				Booking Details
			</h2>
			<hr className='w-full my-5' />
			<div
				className='w-full h-full'
			>
				<div>
					<h1
						className='ps-5 w-full border border-b-0 text-lg font-bold capitalize flex items-center h-10 bg-slate-600'
					>
						customer details
					</h1>
					<table
						className='w-full border'
					>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								customer names:
							</th>
							<td
								className='px-5'
							>
								{booking_data.name}
							</td>
						</tr>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								customer email:
							</th>
							<td
								className='px-5'
							>
								{booking_data.email}
							</td>
						</tr>
					</table>
				</div>
				<div>
					<h1
						className='ps-5 w-full text-slate-200 border-x text-lg font-bold capitalize flex items-center h-10 bg-slate-600'
					>
						accommodation details
					</h1>

					<table
						className='w-full'
					>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								number of rooms:
							</th>
							<td
								className='px-5'
							>
								{booking_data.rooms.length}
							</td>
						</tr>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								room class(<span className='lowercase'>es</span>):
							</th>
							<td
								className='px-5 h-10 flex'
							>
								{booking_data.rooms.map((room, index) => (
									<p
										key={index}
										className={`${booking_data.rooms.length > 1 && 'me-2'} h-full flex items-center`}
									>
										{room.roomClass}{booking_data.rooms.length > 1 && index < booking_data.rooms.length - 1 && ','}
									</p>
								))}
							</td>
						</tr>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								cost per room:
							</th>
							<td
								className='px-5 h-10 flex'
							>
								{booking_data.rooms.map((room, index) => (
									<p
										key={index}
										className={`${booking_data.rooms.length > 1 && 'me-2'} h-full flex items-center`}
									>
										{room.pricePerNight}{booking_data.rooms.length > 1 && index < booking_data.rooms.length - 1 && ','}
									</p>
								))}
							</td>
						</tr>
						<tr
							className='border h-10'
						>
							<th
								className='w-52 px-5 text-slate-300 text-right font-bold border-e capitalize'
							>
								Total cost:
							</th>
							<td
								className='px-5 h-10 flex'
							>
								<p
									// key={index}
									className={`h-full flex items-center`}
								>
									{totalRoomCost}
								</p>
							</td>
						</tr>
					</table>
				</div>

				<div>
					<h1
						className='ps-5 w-full text-slate-200 border-x text-lg font-bold capitalize flex items-center h-10 bg-slate-600'
					>
						special requests
					</h1>
					<p
						className='border px-5 py-2'
					>{booking_data.special_request ?? 'N/A'}</p>
				</div>

				<div
					className='w-3/4 mx-auto h-20 flex items-center gap-10'
				>
					<StyledButton title={'Accept'} className={'w-full'} onClick={() => buttonHandle('accepted')} />
					<StyledButton title={'Reject'} className={'w-full'} onClick={() => buttonHandle('rejected')} />
				</div>

			</div>
		</div>
	)
}

export { BookingDetails }