import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const Collapsable = ({ data }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<div
				className="h-10 md:w-3/4 w-full border-t mx-auto md:p-10 p-8  flex items-center justify-between"
				onClick={
					() => {
						setIsExpanded(!isExpanded);
					}
				}
			>
				<h3 className="md:text-lg">{data.name}</h3>
				<FontAwesomeIcon
					icon={
						isExpanded
							? faMinus
							: faAdd
					}
					onClick={
						() => {
							setIsExpanded(!isExpanded);
						}
					}
					className="text-[#ff722c]"
				/>
			</div>
			<div
				className={`md:w-3/4 w-full mx-auto md:px-10 px-8 overflow-hidden transition-all duration-300 ease-linear 
					text-slate-300 ${isExpanded ? "h-52 md:h-28 md:mt-5 mb-5" : "h-0"
					}`
				}
				onClick={
					() => {
						setIsExpanded(!isExpanded);
					}
				}
			>
				<p
					className="max-md:text-sm max-md:tracking-wide text-justify"
				>
					{data.text}
				</p>
			</div>
		</>
	);
};
