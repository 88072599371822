import React, { useEffect, useState } from 'react';
import { Input } from '../../../components/CustomInputField';
import { StyledButton } from '../../../components/customButton';
import { useSelector } from 'react-redux';
import ScrollableListContainer from '../../../components/scrollableListContainer';
import { FetchHeaders } from '../../../constants/constants';
import Amenity from '../../../components/amenityComponent';
import { ActionGridCard } from '../../../components/gridCard';
import { BookingModel } from '../models/bookingmodel';
import Model from '../../../components/model';
import Spinner from '../../../components/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck } from '@fortawesome/free-solid-svg-icons';

const BookingForm = ({ formType, business }) => {
	const user = useSelector(state => state.user.user);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [check_In, setCheck_In] = useState('');
	const [check_Out, setCheck_Out] = useState('');
	const [roomCount, setRoomCount] = useState(1);
	const [rooms, setRooms] = useState([]);
	const [specialRequest, setSpecial_Request] = useState('');
	const [isDisplayingPrompt, setIsDisplayingPrompt] = useState(false);
	const [isSurePrompt, setIsSurePrompt] = useState(false);
	const [isModelLoading, setIsModelLoading] = useState(false);
	const [submissionResult, setSubmissionResult] = useState('');
	const [submissionResultIcon, setSubmissionResultIcon] = useState({ icon: '', iconColor: '' })

	useEffect(() => {
		setName(user.username);
		setEmail(user.email);
	}, [user])

	const resetForm = () => {
		setCheck_In('');
		setCheck_Out('');
		setPhone('');
		setRoomCount(1);
		setRooms([]);
		setSpecial_Request('')
	}

	const closeModel = () => {
		setIsDisplayingPrompt(false)
		setTimeout(() => {
			setIsModelLoading(false);
			setIsSurePrompt(false);
		}, 100);
	}

	const handleSubmit = async (e) => {
		setIsModelLoading(true);
		setIsDisplayingPrompt(true);

		await fetch(
			`${process.env.REACT_APP_API_URL}/bookings`,
			{
				method: 'POST',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
				body: JSON.stringify(new BookingModel({
					business_id: business.business_id,
					user_id: user.uid,
					email: email,
					name: name,
					phone: phone,
					rooms: rooms,
					check_in: check_In,
					check_out: check_Out,
					special_request: specialRequest
				}))
			})
			.then((result) => {
				if (Number(result.status) === 200) {
					setSubmissionResult(`Booking successfully submitted. You will receive an email from ${business.business_name}`);
					setSubmissionResultIcon({ icon: faCheck, iconColor: 'text-green-500' });
					resetForm();
					setTimeout(() => { setIsSurePrompt(false); setIsModelLoading(false) }, 200);
					return;
				}

				setSubmissionResult(`Booking submission failed. Please try again later.`);
				setSubmissionResultIcon({ icon: faCancel, iconColor: 'text-red-500' });
				setTimeout(() => { setIsSurePrompt(false); setIsModelLoading(false) }, 200);

			})
			.catch((error) => {
				console.log('Not good.');

				console.log(error);
			});
	};

	return (
		<>
			<Model
				isVisible={isDisplayingPrompt}
				onClickClose={closeModel}
			>
				{isModelLoading
					? <Spinner />
					: isSurePrompt
						?
						<>
							<h1 className='text-2xl font-semibold'>Submit booking?</h1>
							<div
								className='w-full flex justify-evenly'
							>
								<button
									className='min-w-32 h-11 rounded-2xl bg-[#ff722cbc] font-semibold text-lg
							capitalize flex items-center justify-center
							hover:bg-[#ff722c] transition duration-300 ease-in-out'

									onClick={handleSubmit}
								>
									yes
								</button>
								<button
									className='min-w-32 h-11 rounded-2xl bg-slate-600 font-semibold text-lg
						capitalize flex items-center justify-center
							hover:bg-slate-700 transition duration-300 ease-in-out'
									onClick={() => setIsDisplayingPrompt(false)}
								>
									no
								</button>
							</div>
						</>
						:
						<div className='w-full h-full flex flex-col items-center justify-center'>
							<FontAwesomeIcon
								icon={submissionResultIcon.icon}
								size='4x'
								className={`${submissionResultIcon.iconColor}`} />

							<p
								className='text-slate-100 w-3/4 text-center tracking-wide font-semibold text-lg'
							>
								{submissionResult}
							</p>
						</div>

				}
			</Model>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					setIsSurePrompt(true);
					setIsDisplayingPrompt(true);
				}}
				className="w-full h-full px-12 mx-auto my-10"
			>
				<label className="block mb-4 flex-grow">
					<span className=" flex-grow">Name:</span>
					<Input
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						required />
				</label>
				<div className='w-full grid grid-cols-2 gap-5'>
					<label className="block mb-4">
						<span className="">Email:</span>
						<Input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</label>
					<label className="block mb-4">
						<span className="">Phone:</span>
						<Input
							value={phone}
							placeholder='26000000000000'
							maxLength={12}
							minLength={10}
							onChange={e => {
								const value = e.target.value;
								setPhone(value);
							}}
							required
						/>
					</label>
				</div>
				<div className="grid grid-cols-2 gap-x-4 mb-4">
					<label className="block">
						<span className="">Check-in Date:</span>
						<Input
							type="date"
							value={check_In}
							onChange={(e) => setCheck_In(e.target.value)}
							required
						/>
					</label>
					<label className="block">
						<span className="">Check-out Date:</span>
						<Input
							type="date"
							value={check_Out}
							onChange={(e) => setCheck_Out(e.target.value)}
						/>
					</label>
				</div>

				{formType === 'accommodations' && <>
					<label className="block mb-4">
						<span className="">Number of Rooms:</span>
						<Input
							type="number"
							value={roomCount}
							onChange={(e) => setRoomCount(e.target.value)}
							min="1"
							max='15'
							required
						/>
					</label>
					<div className={`w-full ${rooms && rooms.length > 0 ? 'h-16' : 'h-0'} 
			transition-all duration-300 ease-in-out flex gap-2 overflow-hidden`}>
						{
							rooms && rooms.map((room, i) => (
								<Amenity
									key={i}
									text={
										<div>
											{room.roomClass}
										</div>
									}
									onClick={() => setRooms(prevState => [...prevState.filter((_, index) => index !== i)])}
								/>
							))
						}
					</div>
					<label className="block mb-4">
						<span className="">Room Class:</span>
						<ScrollableListContainer>
							{business &&
								business.rooms.map(
									(item, i) =>
									(
										<ActionGridCard
											key={i}
											image={item.roomImage}
											title={
												<div
													className='w-full flex justify-between'
												>
													<p>{item.roomClass}</p>
													<p>K{item.pricePerNight}</p>
												</div>}
											subtitle={item.amenities}
											onClick={() =>
												rooms.length < roomCount && setRooms(prevState => [...prevState, item])
											}
										/>
									)
								)
							}</ScrollableListContainer>
					</label>
				</>
				}
				<label className="block mb-4">
					<span className="">Special Requests:</span>
					<textarea
						name="specialRequests"
						value={specialRequest}
						rows={5}
						maxLength={300}
						onChange={(e) => setSpecial_Request(e.target.value)}
						className={`w-full border rounded-xl 
					flex justify-between items-center overflow-hidden
					outline-none p-2 bg-slate-700
					transition duration-300 ease-in
					hover:border-[#ff722c] 
					`}
					/>
				</label>
				<StyledButton
					type={'submit'}
					title={'Submit'}
					className={'w-full rounded-md'}
				/>
			</form>
		</>
	);
};

export default BookingForm;
