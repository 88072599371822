const ServiceCard = ({ service }) => {
	return (
		<div
			className="bg-slate-800 min-h-80 w-full p-5"
		>
			{service && (
				<>
					<h1
						className="text-4xl capitalize font-semibold"
					>
						{service.title}
					</h1>

					<hr className="my-2" />

					<p
						className="px-4 text-slate-100 text-justify text-lg"
					>
						{service.body}
					</p>
				</>
			)}
		</div>
	)
}

export { ServiceCard }