import React, { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { IAuthRoutes, IOtherRoutes, IRoutes } from "../constants/constants";
import { useSelector } from 'react-redux';

export default function NavigationBar({ pageName }) {
	const user = useSelector(state => state.user.user);
	const { pathname } = useLocation();

	const drawerRef = useRef();
	const [isExpanded, setIsExpanded] = useState(false);

	let routes = Object.keys(IRoutes);

	let exclude;

	if (user.roles.includes('viewer')) {
		exclude = ['dashboard'];
	}
	else if (user.roles.includes('editor'))
		exclude = ['home', 'booking', 'tours'];

	else
		exclude = ['dashboard', 'booking', 'tours'];

	routes = routes.filter((route, i) => !exclude.includes(route))

	let pageIndex = routes.indexOf(pageName);


	useEffect(
		() => {
			const observeDrawer = () => {
				const drawer = drawerRef.current;

				const drawerObserver = new IntersectionObserver(([entry]) => {
					if (!entry.isIntersecting) setIsExpanded(false);
				});

				if (drawer) drawerObserver.observe(drawer);

				return () => {
					if (drawer) drawerObserver.unobserve(drawer);
				};
			}

			observeDrawer();
		},
		[]
	);

	return (
		<nav
			className={`${isExpanded ? "h-fit" : "h-20"} md:h-24 w-screen z-30 overflow-hidden absolute 
				${pathname !== IRoutes.home && 'bg-slate-800 border-b-4 border-[#ff722c]'}`
			}
		>
			<div
				className={`w-full flex md:grid md:grid-cols-12 items-center 
					max-md:justify-between max-md:px-2 bg-cover bg-left
					transition-all duration-500 ease-out absolute
					bg-transparent
					} ${isExpanded
						? "-translate-y-full opacity-0 h-0 bg-transparent bg-center"
						: "translate-y-0 h-20 md:h-24"
					}
					`}
			>
				<div className="md:col-span-2 md:mx-auto h-full flex justify-center items-center">
					<a href={IRoutes.home}>
						<img
							src="/logo512.png"
							className="w-20 md:w-24"
							alt="Logo"
							loading="lazy"
						/>
					</a>
				</div>
				<div className="col-span-8 max-md:hidden mx-auto h-full flex gap-2">
					{routes.map((route, index) =>
					(
						<a
							key={index}
							className={`w-24 h-full ${!route && 'hidden'} ${pageIndex === index
								? `text-[#ff722c] scale-110 font-medium`
								: `text-white hover:text-[#ff722c]`
								}
						capitalize flex justify-center items-center
						transition duration-300 ease-out
						text-[#ff722c] text-md`}
							href={IRoutes[route]}
						>
							{route}
						</a>
					))
					}
				</div>

				<div
					className="max-md:hidden w-full h-full col-span-2 flex justify-center items-center"
				>
					{
						user.uid ?
							(
								<a
									href={IOtherRoutes.profile}
									className="w-14 h-14 bg-slate-200 rounded-full text-xl font-extrabold 
										flex justify-center items-center cursor-pointer tracking-wide text-slate-800
										hover:bg-[#ff722c] hover:text-white transition duration-300
										ease-out"
								>
									{
										user.username.slice(0, 1).toUpperCase()
									}
								</a>
							)
							:
							(
								<a
									href={IAuthRoutes.user}
									className="w-32 h-12 bg-[#ff722c] flex items-center justify-center 
										font-semibold text-md hover:text-[#ff722c] hover:bg-white
										transition duration-300 ease-out"
								>
									Signin
								</a>
							)
					}
				</div>

				<div
					className="md:hidden w-16 h-full flex justify-center 
						items-center cursor-pointer"
					onClick={
						() => setIsExpanded(!isExpanded)
					}
				>
					<svg
						className="h-6 w-6"
						fill="none"
						stroke={'white'}
						viewBox="0 0 20 20"
						xmlns="http:www.w3.org/2000/svg"
						xlink="http://www.w3.org/1999/xlink"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M4 6h16M4 12h16m-7 6h7"
						/>
					</svg>
				</div>
			</div>

			<div
				className={`${isExpanded ? "translate-x-0" : "translate-x-full"
					} w-screen h-screen flex reltive`}
				onClick={e => setIsExpanded(false)}
			>
				<div ref={drawerRef} className="flex-grow backdrop-blur">
					{/* logo and close button */}
					<div
						className={`w-full h-20 md:h-24 flex items-center max-md:justify-between 
							max-md:px-2 bg-cover bg-center transition-all duration-500 delay-600
							ease-out ${isExpanded ? "translate-x-0 bg-left" : "translate-x-[200%]"
							} ${pageIndex === 0 && "bg-transparent"}`}
					// style={{ backgroundImage: `${pageIndex !== 0 && "url('/images/sky.jpg')"}` }}
					>
						<div
							className="md:hidden md:col-span-2 md:mx-auto h-full flex 
							justify-center items-center"
							onClick={e => e.stopPropagation()}
						>
							<a href={IRoutes.home}>
								<img
									src="/logo512.png"
									className="w-16 md:w-20"
									alt="Logo"
									loading="lazy"
								/>
							</a>
						</div>
						<div
							className="md:hidden w-16 h-full flex justify-center 
								items-center cursor-pointer"
							onClick={
								e => {
									e.stopPropagation();
									setIsExpanded(false);
								}}

						>
							<svg
								className="h-6 w-6"
								fill="none"
								stroke={'white'}
								viewBox="30 30 50 50"
								xmlns="http:www.w3.org/2000/svg"
								xlink="http://www.w3.org/1999/xlink"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="5"
									d="M 10,10 l 90,90 M 100,10 l -90,90"
								/>
							</svg>
						</div>
					</div>
					<div className="w-full flex flex-col">
						{routes.map((route, index) => (
							<a
								key={index}
								className={`py-2 px-4 border-b border-gray-500 
									 hover:bg-[#ff722c] 
									capitalize transition-all duration-500 ease-in-out 
									${isExpanded ? "translate-x-0" : "translate-x-full"}
									${!route && 'hidden'}`}
								style={{ transitionDelay: `1${index + 2}0ms` }}
								href={IRoutes[route]}
								onClick={e => e.stopPropagation()}
							>
								{route}
							</a>
						)
						)}
						<a
							className={`bloc py-2 px-4 border-b border-gray-500 
									${pageIndex < 1 ? "text-white" : ""} hover:bg-[#ff722c] 
									capitalize transition-all duration-500 delay-200 ease-in-out 
									${isExpanded ? "translate-x-0" : "translate-x-full"
								}`}
							href={IOtherRoutes.profile}
							onClick={e => e.stopPropagation()}
						>
							{user.uid ? 'Profile' : 'signin'}
						</a>
					</div>
					<div
						className="w-full h-10"
					></div>
				</div>
			</div>
		</nav>
	);
} 
