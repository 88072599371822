import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const StyledButton = ({ children, title, onClick, type, className }) => {

	return (
		<button
			onClick={onClick}
			className={`min-w-10 max-w-full h-12 rounded-md border p-2 border-[#ff722c]
							bg-[#ff722c] hover:bg-white hover:text-[#ff722c]
							transition duration-300 ease-in ${className}
							px-5 py-3 text-white font-bold tracking-wide`}
		>
			{children || title}
		</button>
	);
}
const StyledCloseButton = ({ children, title, onClick, type, className }) => {

	return (
		<button
			type={type ? type : "button"}
			onClick={onClick}
			className={`min-w-10 max-w-full h-12 rounded-2xl border p-2 border-red-600
							bg-red-600 hover:bg-white hover:text-red-600
							transition duration-300 ease-in ${className}
							px-5 py-3 text-white font-bold tracking-wide`}
		>
			{children || title || <FontAwesomeIcon icon={faX} />}
		</button>
	);
}

export { StyledButton, StyledCloseButton };