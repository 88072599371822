/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {string[]} roles
 * @param {string} business_id
 * @param {string} nationality
 */

class UserModel {
	constructor({
		username,
		email,
		password,
		roles,
		imageurl,
		business_id,
		nationality
	}) {
		this.username = username ?? Error('username can not be undefined');
		this.email = email ?? Error('email can not be undefined');
		this.password = password ?? Error('password can not be undefined');
		this.roles = roles ?? Error('roles can not be undefined');
		this.imageurl = imageurl ?? '';
		this.business_id = business_id ?? '';
		this.nationality = nationality ?? '';
	}
}

export { UserModel };