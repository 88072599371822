import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FetchHeaders, IAuthRoutes, IOtherRoutes, IRoutes, StorageKeys } from "./constants/constants";
import { useDispatch } from 'react-redux';
import { updateUser } from "./redux/reducers/user";

// pages
import ContactUs from "./pages/contact/contactus";
import Faq from "./pages/faq/faq";
import Services from "./pages/services/services";
import Error404 from "./pages/error/404";
import Home from "./pages/landing/home";
import Booking from "./pages/booking/booking";
import Tours from "./pages/tours/tours";
import UserAuth from "./pages/onboarding/userauth";
import Profile from "./pages/home/userprofile";
import FindMe from "./pages/findme/findme";
import BookingFrame from "./pages/booking/components/frame";
import Dashboard from "./pages/dashboard/dashboard";
import Events from "./pages/events/events";

export default function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		let storedUser = window.localStorage.getItem(StorageKeys.user);

		if (!storedUser) return;

		storedUser = JSON.parse(storedUser);

		dispatch(updateUser(storedUser))

		const refreshUser = async () => {
			const user = storedUser;

			await fetch(
				`${process.env.REACT_APP_API_URL}/user/user/${user.uid}`, {
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},

			}).then(async res => {
				if (res.status === 200) {
					const data = await res.json();

					window.localStorage
						.setItem(StorageKeys.user, JSON.stringify({
							...user, ...data.user
						}));
				}
			}).catch(error => {
				console.log(error);
			})
		}

		const refreshToken = async () => {
			let user = storedUser;
			let accessTokenLife = storedUser.authToken.createdOn

			if (new Date().getTime() - Number(accessTokenLife) < 890000)
				return

			await fetch(
				`${process.env.REACT_APP_API_URL}/user/refresh`, {
				method: 'POST',
				headers: FetchHeaders,
				body: JSON.stringify({ token: user.refreshToken.token, email: user.email })
			}
			)
				.then(async res => {
					const token = await res.json();

					if (!token) return;

					console.log('token refreshed')

					let updatedUser = {
						...storedUser,
						authToken: {
							...storedUser.authToken,
							createdOn: new Date().getTime(),
							token: token.accessToken
						}
					}

					dispatch(updateUser(updatedUser));

					window.localStorage.setItem(StorageKeys.user, JSON.stringify(updatedUser))
				})
				.catch(error => console.log(error));
		};

		refreshToken();
		refreshUser();

	}, [dispatch])

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="*"
					Component={Error404}
				/>
				<Route
					path={IAuthRoutes.user}
					Component={UserAuth}
				/>
				<Route
					path={IRoutes.home}
					Component={Home}
				/>
				<Route
					path={IRoutes.services}
					Component={Services}
				/>
				<Route
					path={IRoutes.contact}
					Component={ContactUs}
				/>
				<Route
					path={IRoutes.booking}
				>
					<Route
						path={IRoutes.booking}
						Component={Booking}
					/>
					<Route
						path={IRoutes.booking + ':table/:column/:value'}
						Component={BookingFrame}
					/>
				</Route>
				<Route
					path={IRoutes.tours}
					Component={Tours}
				/>
				<Route
					path={IRoutes.FAQ}
					Component={Faq}
				/>
				<Route
					path={IOtherRoutes.profile}
					Component={Profile}
				/>
				<Route
					path={IRoutes.findme}
					Component={FindMe}
				/>
				<Route
					path={IRoutes.dashboard}
				>
					<Route
						path={IRoutes.dashboard}
						Component={Dashboard}
					/>
					<Route
						path={IRoutes.dashboard + ':type/:id'}
						Component={Dashboard}
					/>
				</Route>
				<Route
					path={IRoutes.events}
				>
					<Route
						path={IRoutes.events}
						Component={Events}
					/>
					<Route
						path={IRoutes.events + ':id'}
						Component={Events}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
