import React from 'react';
import { CStyles } from '../constants/constants';

export default function Model({
	isVisible,
	onClickClose,
	children,
	inner_style
}) {

	return (
		<div
			className={`absolute inset-0 min-h-screen w-screen backdrop-brightness-25
					${CStyles.transition_300} px-5
					${isVisible ? 'z-10 scale-100 opacity-100' : '-z-10 scale-95 opacity-0'}
					flex justify-center items-center
				`}
			onClick={onClickClose}
		>
			<div
				className={`w-96 h-72 flex flex-col gap-10 bg-slate-800 rounded-2xl
						justify-center items-center relative overflow-hidden ${inner_style}`}
				onClick={(event) => event.stopPropagation()}
			>
				{children}
			</div>
		</div >

	);
}