import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { CStyles } from '../../../constants/constants';

export default function ListItem({ business_id, business_name, table, category, location, image, rating, href }) {
	const textRef = useRef()

	return (
		<div
			className={`h-60 md:h-72 shadow-lg rounded-2xl hover:scale-[1.01] ${CStyles.transition_300}`}
			onClick={e => e.stopPropagation()}
			onMouseEnter={() => {
				textRef.current.classList.add('text-[#ff722c]');
			}}
			onMouseLeave={() => {
				textRef.current.classList.remove('text-[#ff722c]');
			}}
		>
			<a
				href={href}
				className={`min-w-30 md:min-w-60 max-h-56 flex flex-col gap-1
					${CStyles.transition_300} rounded-2xl`}
			>
				<div
					className='w-full h-36 md:h-56'
				>
					<img
						alt={business_name}
						className={`object-cover rounded-t-xl w-full h-36 md:h-56 border bg-slate-500`}
						loading='lazy'
						src={`${image}`}
						srcSet={`${image}?tr=w-400 400w, ${image}?tr=w-800 800w`}
					/>
				</div>
				<div
					className='w-full h-full flex flex-col ps-3'
				>
					<h2
						ref={textRef}
						className={`capitalize md:text-lg font-semibold 
							flex justify-between ${CStyles.transition_300} pe-5`
						}
					>
						{business_name}
						<span
							className='md:text-xl font-bold flex sm:items-center gap-2'
						>
							<FontAwesomeIcon
								icon={faStar}
								className='md:text-md max-sm:pt-1 text-orange-600' />
							{rating}
						</span>
					</h2>

					<p
						className='text-sm text-slate-500 capitalize'
					>
						{location.town},{" "}
						{location.province}
					</p>
				</div>
			</a>
		</div>
	);
}