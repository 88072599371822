/**
 * @param {string} business_id
 * @param {string} type
 * @param {string} business_name
 * @param {string} email
 * @param {string} phone
 * @param {string} image
 * @param {string} about
 * @param {string} location
 * @param {Number} rating
 */
class Accommodation {
	constructor({
		business_id,
		business_name,
		type,
		email,
		phone,
		image,
		about,
		location,
		rating,
		rooms,
	}) {
		this.business_id = business_id ?? '';
		this.business_name = business_name ?? '';
		this.type = type ?? null;
		this.email = email ?? '';
		this.phone = phone ?? ''
		this.image = image ?? '';
		this.about = about ?? '';
		this.location = location ?? {};
		this.rating = rating ?? 0.0;
		this.rooms = rooms ?? [];
	};
}

export { Accommodation }