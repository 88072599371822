// import { useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import Footer from "../../components/footer";
import { Collapsable } from "../../components/collapsable";
import { IRoutes } from "../../constants/constants";

export default function Faq() {
	const faQuest = [
		{
			name: "Can I use this outsite of Zambia?",
			text: `Yes, you certainly can! However, it's important to note that our 
				services are currently more extensively available within Zambia. 
				While we are continuously expanding, some features may be limited 
				outside the country. Rest assured, we are working towards broader 
				accessibility to enhance your experience beyond Zambia in the future.`,
		},
		{
			name: "Is my data secure?",
			text: `Yes, absolutely. At our core, we prioritize customer security and 
				confidentiality. Your information is treated with the utmost care 
				and is safeguarded through robust protective measures. Rest assured 
				that we are committed to ensuring the safety and privacy of your data.`,
		},
		{
			name: "Do I get a discount if use the app to find accomodation?",
			text: `Absolutely! When you use the app, you may enjoy a certain percentage 
				of discount applied to your accommodation charges. It's our way of 
				enhancing your experience and providing added value for choosing our 
				platform. Take advantage of exclusive savings and make your stay even 
				more rewarding with our special discounts.`,
		},
		{
			name: "Is there a cancellation charge for cancelling booking?",
			text: `No, there will be no charge incurred for canceling a booking. 
				We prioritize your flexibility and autonomy, allowing you to cancel your
				booking at any time without incurring any financial penalty or consequences.`,
		},
	];

	return (
		<>
			<NavigationBar pageName={IRoutes.FAQ.substring(1)} />
			<div
				className="w-screen min-h-screen pt-16 md:pt-10"
			>
				<div
					className="md:container mx-auto md:p-10 p-5"
				>
					<h2
						className="text-center md:text-4xl sm:text-2xl text-xl text-slate-50 
							font-bold tracking-wider capitalize md:my-16 my-10"
					>
						Frequently asked question
					</h2>
					<div
						className="w-full h-full"
					>
						{
							faQuest.map(
								(question, index) => (
									<Collapsable
										key={index}
										data={question}
									/>
								)
							)
						}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
