import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faBriefcase, faCancel, faDollar, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { FetchHeaders } from '../../../constants/constants';

export default function Itinerary() {
	const user = useSelector(state => state.user.user);
	const [itineraryOptionIndex, setItineraryOptionIndex] = useState(0);
	const [bookings, setBookings] = useState([]);

	const itineraryOptions = [
		// 'trips',
		'bookings',
		'savings',
		// 'misc'
	];

	useEffect(() => {
		const fetchBookings = async () => {
			if (!user) return;

			await fetch(
				`${process.env.REACT_APP_API_URL}/bookings/${user.roles}/${user.uid}`,
				{
					method: 'GET',
					headers: {
						"authorization": `Bearer ${user.authToken.token}`,
						...FetchHeaders
					},
				}
			)
				.then(async result => {
					const response = await result.json();

					if (response.data.length < 1)
						console.log('No data fetched.');

					setBookings(response.data);
				})
				.catch(error => {
					console.log(error)
				})
		}

		fetchBookings();
	}, [user])

	return (
		<div
			className={`min-w-full min-h-full p-4 flex flex-col gap-5
				transition-all duration-300 ease-in-out tranform
			`}
		>
			<div
				className='flex gap-2'
			>
				{itineraryOptions.map((itOption, i) => {
					let icon;
					let isActive = i === itineraryOptionIndex ?? true;

					switch (itOption) {
						case 'trips':
							icon = faPlaneDeparture;
							break;
						case 'bookings':
							icon = faBriefcase;
							break;
						case 'savings':
							icon = faDollar;
							break;
						case 'misc':
							icon = faAddressBook;
							break;
						default:
							icon = faCancel;
							break;
					}

					return (
						<div
							key={i}
							className={`md:w-full h-12 rounded-2xl bg-slate-700 flex-grow
								flex justify-between items-center px-5 gap-3 hover:text-[#ff722c]
								transition-all duration-300 ease-in-out cursor-pointer 
								${isActive && 'text-[#ff722c]'}
								`}
							onClick={() => setItineraryOptionIndex(i)}

						>
							<FontAwesomeIcon icon={icon} className='w-8' />
							<p
								className='w-full capitalize'
							>
								{itOption}
							</p>
						</div>
					)
				})}

			</div>
			<div
				className='flex flex-col justify-center overflow-hidden'
			>
				<table className='border'>
					<thead
						className={`w-full h-14 border bg-slate-600 capitalize`}
					>
						<th
							className='border-e w-14 border-slate-500'
						>
							No.
						</th>
						<th
							className='border-e border-slate-500'
						>
							Place
						</th>
						<th
							className='border-e border-slate-500'
						>
							Location
						</th>
						<th
							className='border-e border-slate-500'
						>
							check_In
						</th>
						<th
							className='border-e border-slate-500'
						>
							check_Out
						</th>
						<th
							className='border-e border-slate-500'
						>
							Cost
						</th>
						<th>
							status
						</th>
					</thead>
					<tbody>
						{bookings && bookings.map((booking, i) => (
							<tr
								key={i}
								className={`text-center h-10 border-b 
								cursor-pointer hover:bg-slate-500 transition-all duration-300 ease-in-out`}
							>
								<td className="border-e">{i + 1}</td>
								<td className='border-e'>
									{booking.business_name}
								</td>
								<td className='border-e capitalize'>
									{booking.location.town}, {booking.location.province}
								</td>
								<td className='border-e'>
									{booking.check_in.split('T')[0]}
								</td>
								<td className='border-e'>
									{booking.check_out.split('T')[0]}
								</td>
								<td className='border-e'>
									K{booking.rooms[0].pricePerNight}
								</td>
								<td className=''>
									{booking.status}
								</td>
							</tr>
						))}

					</tbody>
				</table>
			</div>
		</div>
	);
}