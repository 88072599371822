import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AboutUsSection from "./components/about_us_section";
import HeroSection from "./components/hero_section";
import Testimonials from "./components/testimonials_section";
import NavigationBar from '../../components/NavigationBar';
import Footer from '../../components/footer';
import { EventsSection } from "./components/events_section";
import NewsSection from "./components/news_section";
import { IRoutes } from "../../constants/constants";


export default function Home() {
	const user = useSelector(state => state.user.user);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!user.uid)
			return;

		if (user.roles.includes('editor'))
			navigate(IRoutes.dashboard);

	});

	return (
		<>
			<NavigationBar pageName={'home'} />
			<div
				className="w-screen h-fit flex flex-col items-center justify-center"
			>
				<HeroSection user={user} />
				<AboutUsSection />
				<NewsSection />
				<EventsSection />
				<Testimonials />
			</div>
			<Footer />
		</>
	);
}
