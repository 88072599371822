import React from 'react';

const NothingToShow = (params) => {

	return (
		<div
			className='w-full h-96 self-center flex items-center justify-center'>
			<h1
				className='text-6xl text-slate-500 font-semibold text-center'>
				Nothing to show.
			</h1>
		</div>
	);
}

export { NothingToShow }