import React, { useState } from "react"
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { faClockRotateLeft, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "./CustomInputField"
import { CStyles } from "../constants/constants"

const SubNavWithSearch = (
	{
		options,
		searchPlaceholder,
		onSearch,
		onSearchTextChange,
		searchSuggestions = []
	}) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSerachQuery] = useState(searchParams.get('location'))

	const [activeOption, setActiveOption] = useState(0)

	const handleSuggestionClick = (suggestion) => {
		setSerachQuery(suggestion)
		setSearchParams({ location: suggestion })
	}

	return (
		<div
			className='w-full bg-slate-800 md:h-16 px-20 max-md:px-5 grid max-md:grid-rows-2 md:grid-cols-3 shadow-lg'
		>
			<div
				className='w-full h-14 md:col-span-2 grid grid-cols-4 md:px-2 self-center items-center'
			>
				{
					options &&
					options.map((option, index) =>
					(
						<p
							key={index}
							className={`w-full text-center cursor-pointer capitalize
										hover:text-[#ff722c] hover:border-x ${CStyles.transition_300} 
										${options.length !== 4 && index === 1 && 'col-span-2'}
										${index === activeOption && 'text-[#ff722c] border-x'}`}
							onClick={() => {
								setActiveOption(index)
								option.action()
							}}
						>
							{option.title}
						</p>
					)
					)
				}
			</div>
			<div
				className='w-full h-12 col-span-1 self-center flex items-center relative'
			>
				<Input
					placeholder={searchPlaceholder}
					borderFocusColor='border-slate-800'
					borderHoverColor='border-slate-800'
					value={searchQuery}
					onChange={e => setSerachQuery(e.target.value.toLowerCase())}
					onKeyDown={(e) => {
						let keyCode = e.code

						if (keyCode === 'Enter')
							setSearchParams({ location: searchQuery })
					}}
					postfix={
						<div
							className='w-14 flex justify-center items-center text-2xl cursor-pointer border-s border-slate-500
								hover:text-[#ff722c] transition-all
										duration-300 ease-in-out'
							onClick={() => setSearchParams({ location: searchQuery })}
						>
							<FontAwesomeIcon icon={faSearch} />
						</div>
					}
				/>
				{
					searchQuery &&
					<div
						className="w-full h-fit absolute inset-y-16 rounded-2xl shadow-md bg-slate-600 z-30 overflow-hidden"
					>
						{
							searchSuggestions
								.filter((suggestion) => suggestion.includes(searchQuery))
								.map((suggestion, index) => (
									<div
										key={index}
										className={`w-full h-10 px-4 text-slate-400 flex gap-5 text-lg items-center 
							hover:bg-slate-50 cursor-pointer border-b border-slate-500 ${CStyles.transition_300}`}
										onClick={() => handleSuggestionClick(suggestion)}
									>
										<FontAwesomeIcon icon={faClockRotateLeft} />
										<p className="w-full">{suggestion}</p>
									</div>
								))
						}
					</div>
				}
			</div>
		</div>
	)
}

export { SubNavWithSearch }