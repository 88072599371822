import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FetchHeaders, IRoutes } from '../../../constants/constants';
import { Card, MiniCard } from '../../../components/cards';
import { Event } from '../../events/models/eventModel';
import { useCallback, useEffect, useState } from 'react';
import { SectionHeader } from '../../../components/section_header';

const EventsSection = () => {
	const navigate = useNavigate();
	const user = useSelector(state => state.user.user);
	const [events, setEvents] = useState([]);


	const fetchEvents = useCallback(async () => {
		await fetch(
			`${process.env.REACT_APP_API_URL}/events/6`,
			// `http://127.0.0.1:2000/events/6`,
			{
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
			})
			.then(async results => {
				const { data } = await results.json();

				if (!data) {
					return console.log('No data.')
				}

				setEvents(data)
			})
			.catch(error => {
				console.log(error);
			})
	}, [user.authToken.token])

	useEffect(() => {
		const getEvents = async () => {
			await fetchEvents();
		};

		getEvents();
	}, [fetchEvents]);

	return (
		<div className="w-full h-fit sm:h-[200vh] md:h-fit bg-slate-800">

			<div
				className="w-full h-full container mx-auto py-5 px-10 max-md:px-5"
			>
				<SectionHeader title={'Events'} onClickViewAll={() => navigate(IRoutes.events)} />
				<div
					className="w-full py-5"
				>
					<h2 className="text-xl font-bold tracking-tight text-blue-200 sm:text-2xl">This Week</h2>

					{/* Map event cards */}

					<div className="w-full h-80 grid grid-cols-2 gap-5 py-3">
						{
							events.length > 0 ? (
								events
									.filter((_, i) => i < 2)
									.map((item, i) => (
										<MiniCard
											key={i}
											data_object={new Event(item).toCard()}
										/>
									))
							) : (
								<>
									<Card />
									<Card />
								</>
							)}
					</div>
				</div>
				<div
					className="w-full py-5"
				>
					<h2 className="text-xl font-bold tracking-tight text-blue-200 sm:text-2xl">This Month</h2>

					{/* Map event cards */}

					<div className="w-full h-40 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 py-3">
						{
							events.length > 0 ? (
								events
									.filter((_, i) => i < 4)
									.map((item, i) => (
										<MiniCard
											key={i}
											data_object={new Event(item).toCard()}
										/>
									))
							) : (
								<>
									<MiniCard />
									<MiniCard />
									<MiniCard />
									<MiniCard />
								</>
							)}
					</div>
				</div>

			</div>
		</div>
	);
}

export { EventsSection };