import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NavigationBar from '../../components/NavigationBar'
import Footer from '../../components/footer'
import { FetchHeaders, IRoutes } from '../../constants/constants'
import { SubNavWithSearch } from '../../components/subNavBar'
import { Event } from './models/eventModel'
import { EventCard } from './components/eventCard'
import { EventDetail } from './components/eventDetails'

export default function Events(params) {
	const user = useSelector(state => state.user.user)
	const { id } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()

	const [events, setEvents] = useState([])

	const OptionAction = (timeLine) => {
		setSearchParams({ view: timeLine })
	}

	const options = [
		{
			title: 'today',
			action: () => OptionAction('today')
		},
		{
			title: 'this week',
			action: () => OptionAction('this_week')
		},
		{
			title: 'this month',
			action: () => OptionAction('this_month')
		},
		{
			title: 'event calendar',
			action: () => OptionAction('event_calendar')
		}
	]

	const fetchEvents = useCallback(async () => {
		await fetch(
			`${process.env.REACT_APP_API_URL}/events/1000`,
			{
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
			})
			.then(async results => {
				const { data } = await results.json()

				if (!data) {
					return console.log('No data.')
				}

				setEvents(data)
			})
			.catch(error => {
				console.log(error)
			})
	}, [user.authToken.token])

	useEffect(
		() => {
			const getEvents = async () => await
				fetchEvents()

			getEvents()
		}, [fetchEvents])

	return (
		<>
			<NavigationBar pageName={IRoutes.events.substring(1)} />
			<div
				className='w-screen min-h-screen pt-20 md:pt-24 overflow-x-hidden'
			>
				{
					!id && <SubNavWithSearch
						options={options}
						searchPlaceholder={'Search by location'}
					/>
				}
				<div
					className='container mx-auto p-10 max-md:p-2'
				>
					{
						id ?
							(
								<EventDetail
									event={events.filter((event) => event.event_id === id)[0]}
								/>
							) :
							(
								<div
									className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'
								>
									{
										events.length > 0 &&
										events.map(event => (
											<EventCard
												key={event.id}
												event={new Event(event).toCard()}
											/>
										))
									}
								</div>
							)
					}
				</div>
			</div>
			<Footer />
		</>
	)
}