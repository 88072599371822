class LocationModel {
	constructor({
		street,
		town,
		province
	}) {
		this.street = street ?? '';
		this.town = town ?? '';
		this.province = province ?? '';
	}
}

export { LocationModel };