import React, { useCallback, useEffect, useState } from 'react';
import { FetchHeaders, StorageKeys } from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faLocationDot, faPaperPlane, faPhone, faStar } from '@fortawesome/free-solid-svg-icons';
import { StyledButton, StyledCloseButton } from '../../../components/customButton';
import BookingForm from '../components/bookingForm';
import { Input } from '../../../components/CustomInputField';

export default function BookingDetails({ table, column, category, entityId, }) {
	const [isLoading, setIsLoading] = useState(true);
	const [isBooking, setIsBooking] = useState(false);
	const [entity, setEntity] = useState();
	const [review, setReview] = useState('');


	const getEntityCache = useCallback(async () => {
		setIsLoading(true);
		return new Promise((resolve) => {
			let cachedEntities = window
				.localStorage
				.getItem(StorageKeys.booking + `:${table}`);

			if (!cachedEntities) {
				setTimeout(() => setIsLoading(false), 1000);
				resolve(null);
				return;
			}

			try {
				cachedEntities = JSON.parse(cachedEntities);
				const [cachedEntity] = cachedEntities.filter(entity => entity.business_id === entityId);

				cachedEntity && resolve(cachedEntity);

				setTimeout(() => setIsLoading(false), 500);
			}
			catch (error) {
				console.log(error);
				setTimeout(() => setIsLoading(false), 500);
			}
		});
	}, [entityId, table])

	const fetchEntity = useCallback(async () => {
		setIsLoading(true);
		let user = window.localStorage.getItem(StorageKeys.user);

		if (!user) return false;

		user = JSON.parse(user);

		await fetch(
			`${process.env.REACT_APP_API_URL}/${table}/business_id/${entityId}`,
			// `http://127.0.0.1:2000/${table}/business_id/${entityId}`,
			{
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
			})
			.then(async res => {
				const resData = await res.json();

				if (!resData) {
					getEntityCache();
					return;
				}

				console.log('Data fetched.');
				setEntity(resData.data[0]);

				window
					.localStorage
					.setItem(
						StorageKeys.booking + `:${table}`,
						JSON.stringify([resData.data[0]])
					)

				setTimeout(() => setIsLoading(false), 500);
				return true;
			})
			.catch(error => {
				console.log(error);
				getEntityCache();
				setTimeout(() => setIsLoading(false), 500);
				return false;
			});
	}, [entityId, getEntityCache, table])

	useEffect(() => {
		const getData = async () => {
			const cache = await getEntityCache();

			if (cache)
				setEntity(cache);
			else
				fetchEntity();

		};

		getData();
	}, [fetchEntity, getEntityCache])

	return isLoading
		? (
			<div
				className='w-full flex flex-col gap-5 pb-5'
			>
				<div
					className='w-full h-96 bg-slate-800 rounded-2xl animate-pulse'
				/>
				<div
					className='w-full min-h-52 bg-slate-800 rounded-2xl p-5 animate-pulse'
				/>
			</div>

		)
		: (
			<div
				className='w-full flex flex-col gap-5 pb-5'
			>
				<div
					className='w-full h-96 bg-slate-800 rounded-2xl overflow-hidden relative'
				>
					<img
						src={entity && entity.image}
						alt={entity && entity.business_name}
						className='max-md:h-full object-cover object-center absolute z-0'
					/>
					<div
						className='z-0 h-full w-full p-2 md:px-5 md:p-4 flex items-end bg-transparent'
					>
						<div
							className='z-10 md:h-32 w-full py-3 grid grid-rows-2 gap-2'
						>
							<div
								className='w-full flex items-center justify-between px-5 bg-slate-600 rounded-2xl'
							>
								<h1
									className='text-3xl md:text-4xl capitalize'>
									{entity && entity.business_name}
								</h1>
								<div
									className='w-20 flex justify-center items-center gap-2'
								>
									<FontAwesomeIcon icon={faStar} className='text-orange-600' />
									<h1
										className='text-4xl'>
										{entity && entity.rating}
									</h1>
								</div>
							</div>
							<div
								className='row-span-1 bg-slate-600 rounded-2xl grid max-md:grid-rows-3 md:grid-cols-3 max-md:py-2 gap-3 md:gap-5 px-5'
							>
								<div
									className='flex gap-2 h-full items-center'
								>
									<FontAwesomeIcon icon={faLocationDot} />
									<p
										className='capitalize'>
										{entity &&
											`${entity.location.street !== ''
												? entity.location.street + ','
												: ''} 
										${entity.location.town}, 
										${entity.location.province}`}
									</p>
								</div>
								<div
									className='flex gap-2 h-full items-center'
								>
									<FontAwesomeIcon icon={faAt} />
									<p>
										{entity && entity.email}
									</p>
								</div>
								<div
									className='flex gap-2 h-full items-center'
								>
									<FontAwesomeIcon icon={faPhone} />
									<p>
										{entity && entity.phone}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className='w-full min-h-20 bg-slate-800 rounded-2xl p-5'
				>
					<p className="font-semibold ">{entity && entity.about}</p>
				</div>
				<div
					className={`w-full ${isBooking ? 'h-0 p-0' : 'h-20 p-5'} overflow-hidden bg-slate-800 rounded-2xl  flex justify-center
					transition-all duration-300 ease-in-out
				`}
				>
					<StyledButton title={'Book Now!'} onClick={() => setIsBooking(true)} className={'w-96 rounded-md'} />
				</div>
				<div
					className={`w-full ${isBooking ? 'min-h-96 p-5' : 'h-0 p-0'} overflow-hidden bg-slate-800 rounded-2xl flex flex-col
					transition-all duration-300 ease-in-out
				`}
				>
					<div
						className='w-full flex justify-between items-center border-b p-2 px-5'
					>
						<h1
							className='text-xl'
						>
							Booking Application
						</h1>

						<div
							className='max-w-14 justify-self-end'
						>
							<StyledCloseButton onClick={() => setIsBooking(false)} />
						</div>
					</div>
					<div
						className='w-full h-full flex justify-center'
					>
						<BookingForm
							formType={table}
							business={entity}
						/>
					</div >
				</div >
				<div
					className='w-full min-h-96 flex flex-col bg-slate-800 rounded-2xl p-5 gap-2 pb-2'
				>
					<div
						className='w-full flex justify-between items-center border-b p-2 px-5'
					>
						<h1
							className='text-xl'>
							Reviews
						</h1>
					</div>
					<div
						className='w-full h-20 rounded-2xl px-5'>
						<Input
							placeholder={'Write a review.'}
							onChange={e => setReview(e.target.value)}
							postfix={
								<div
									className='w-10 h-full flex items-center justify-center cursor-pointer'
									onClick={() => { alert(review) }}
								>
									<FontAwesomeIcon
										icon={faPaperPlane}
										className='w-10' />
								</div>
							}
						/>
					</div>

					<div
						className='w-full h-full px-5'
					>
						<div
							className='w-full h-52 rounded-2xl bg-slate-600'
						>

						</div>
					</div>
				</div>
			</div >
		);
}