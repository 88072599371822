import React from 'react';
import ScrollableListContainer from '../../../components/scrollableListContainer';
import { GridCard } from '../../../components/gridCard';
import { IAssetURLs, IRoutes } from '../../../constants/constants';
import { BannerAd } from '../../../components/adContainer';

export default function Lodging() {
	const lodgings = [
		{
			label: 'hotels',
			image: `${IAssetURLs.imagekitBucket}/hotel.jpg`,
			url: IRoutes.booking + '/accommodations/type/hotel'
		},
		{
			label: 'motels',
			image: `${IAssetURLs.imagekitBucket}/motel.jpg`,
			url: IRoutes.booking + '/accommodations/type/motel'
		},
		{
			label: 'lodges',
			image: `${IAssetURLs.imagekitBucket}/lodge.jpg`,
			url: IRoutes.booking + '/accommodations/type/lodge'
		},
		{
			label: 'resorts',
			image: `${IAssetURLs.imagekitBucket}/resort.jpg`,
			url: IRoutes.booking + '/accommodations/type/resort'
		},
		{
			label: 'guest houses',
			image: `${IAssetURLs.imagekitBucket}/guest.jpg`,
			url: IRoutes.booking + '/accommodations/type/guest house'
		},
		{
			label: 'inns',
			image: `${IAssetURLs.imagekitBucket}/inn.jpg`,
			url: IRoutes.booking + '/accommodations/type/inn'
		}]

	const destinations = [
		{
			label: 'lusaka',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/lusaka.jpg`,
			url: IRoutes.booking + '/accommodations/location/lusaka'
		},
		{
			label: 'livingstone',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/livingstone.jpg`,
			url: IRoutes.booking + '/accommodations/location/livingstone'
		},
		{
			label: 'kafue',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/kafue.jpg`,
			url: IRoutes.booking + '/accommodations/location/kafue'
		},
		{
			label: 'chipata',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/livingstone.jpg`,
			url: IRoutes.booking + '/accommodations/location/chipata'
		},
		{
			label: 'ndola',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/livingstone.jpg`,
			url: IRoutes.booking + '/accommodations/location/ndola'
		},
		{
			label: 'siavonga',
			subtitle: "100 lodgings",
			image: `${IAssetURLs.imagekitBucket}/siavonga.jpg`,
			url: IRoutes.booking + '/accommodations/location/siavonga'
		}]

	return (
		<div
			className={`min-w-full h-full transform transition-all duration-500 delay-100 ease-in-out px-5 sm:px-8 md:px-7`}
		>
			<ScrollableListContainer
				title={"Browse by category"}
			> 	{
					lodgings.map(
						(item, i) =>
						(
							<GridCard
								key={i}
								href={item.url}
								image={item.image}
								title={item.label}
								subtitle={item.subtitle}
							/>
						)
					)
				}</ScrollableListContainer>
			<BannerAd />
			<ScrollableListContainer
				title={"Browse by place"}
				subHeading={"These popular destinations have a lot to offer"}
			> 	{
					destinations.map(
						(item, i) =>
						(
							<GridCard
								key={i}
								href={item.url}
								image={item.image}
								title={item.label}
								subtitle={item.subtitle}
							/>
						)
					)
				}</ScrollableListContainer>
		</div>
	);
}