import { faAt, faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { CStyles } from "../constants/constants";

const Input = (
	{
		placeholder,
		type = 'text',
		onChange,
		onSubmit,
		onKeyDown,
		required,
		name,
		value,
		prefix,
		postfix,
		min,
		minLength,
		max,
		maxLength,
		multiple,
		borderFocusColor = 'border-[#ff722c]',
		borderHoverColor = 'border-[#ff722c]',
		className,
		disabled,
		height,
		step,
		bg,
		accept,
		id
	}) => {
	const containerRef = useRef();

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	let prefixIcon;
	let postfixIcon;
	let passType;

	switch (type) {
		case 'email':
			prefixIcon = faAt;
			break;
		case 'password':
			prefixIcon = faLock;
			postfixIcon = isPasswordVisible ? faEyeSlash : faEye;
			passType = isPasswordVisible ? 'text' : 'password';
			break;
		default:
			break;
	}

	return (
		<div
			ref={containerRef}
			className={
				`w-full ${height ?? 'h-10'} border border-slate-900 rounded-xl 
				flex justify-between items-center overflow-hidden bg-slate-700
				transition duration-300 ease-in ${bg}
				hover:${borderHoverColor} 
				`
			}
		>
			{prefix ||
				(prefixIcon &&
					<div
						className={`w-10 h-full rounded-e-2xl flex justify-center items-center text-lg`}
					>
						<FontAwesomeIcon icon={prefixIcon} />
					</div>
				)
			}
			<input
				type={type === 'password' ? passType : type}
				value={value}
				placeholder={placeholder}
				required={required}
				onChange={onChange}
				step={step}
				min={min}
				minLength={type === 'password' ? 8 : minLength}
				max={max}
				maxLength={type === 'password' ? 30 : maxLength}
				multiple={multiple}
				className={`outline-none flex-grow h-full bg-slate-700 px-2`}
				name={name}
				disabled={disabled}
				accept={accept}
				id={id}
				onKeyDown={onKeyDown}
				onSubmit={onSubmit}
				onFocus={
					() => {
						containerRef.current.classList.add(borderFocusColor)
					}
				}
				onBlur={
					() => {
						containerRef.current.classList.remove(borderFocusColor)
					}
				}
			/>
			{postfix ||
				(postfixIcon &&
					<div
						className='w-10 h-full rounded-e-2xl flex justify-center items-center text-lg cursor-pointer'
						onClick={() => setIsPasswordVisible(!isPasswordVisible)}
					>
						<FontAwesomeIcon icon={postfixIcon} />
					</div>
				)
			}
		</div>
	)
}

const Select = ({
	onChange,
	required,
	name,
	value,
	borderFocusColor = 'border-[#ff722c]',
	borderHoverColor = 'border-[#ff722c]',
	className,
	children,
	disabled
}) => {
	return (
		<select
			onChange={onChange}
			required={required}
			name={name}
			value={value}
			disabled={disabled}
			className={`w-full h-10 border border-slate-900 rounded-xl bg-slate-700
				flex justify-between items-center overflow-hidden
				${CStyles.transition_300} outline-none focus:${borderFocusColor}
				${disabled ? '' : `hover:${borderHoverColor}`} ${className}
				`}>
			{children}
		</select >
	)
}

export { Input, Select };