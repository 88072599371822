import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEnvelope,
	faLocationPin,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";

import NavigationBar from "../../components/NavigationBar";
import Footer from "../../components/footer";
import { CStyles, IRoutes } from "../../constants/constants";

export default function ContactUs() {
	const contact = {
		phone: "+260969321817",
		address: "lotti house, Cairo Rd, Lusaka, Zambia",
		email: "zedtour.arrant@gmail.com",
	};
	return (
		<>
			<NavigationBar pageName={IRoutes.contact.substring(1)} />
			<div className="flex items-center justify-center h-screen w-screen max-md:h-fit bg-slate-700 max-md:pt-16">
				<div className="md:container w-full flex max-md:flex-col items-center justify-center h-screen max-md:h-fit">
					<div className="h-1/2 w-full flex flex-col items-center justify-center">
						<img src="logo192.png" alt="" className="w-48" />
						<div className="w-full h-full flex flex-col md:gap-4 gap-2 items-center pb-5">
							<a href={`mailto:${contact.email}`}>
								<FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
								{contact.email}
							</a>
							<a href={`tel:${contact.phone}`}>
								<FontAwesomeIcon icon={faPhone} style={styles.icon} />
								{contact.phone}
							</a>
							<a href={`#${contact.address}`}>
								<FontAwesomeIcon icon={faLocationPin} style={styles.icon} />
								{contact.address}
							</a>
						</div>
					</div>
					{/* <div className="h-full w-full m-5"> */}
					<form className="flex flex-col items-center justify-center p-5 bg-slate-800 w-full md:h-full h-1/2 gap-5">
						<h2 className="text-3xl font-bold max-md:mb-5">
							Get in Touch
						</h2>
						<input
							className={`h-10 w-11/12 sm:w-3/5 p-3 border border-slate-900 rounded-xl bg-slate-700
										flex justify-between items-center overflow-hidden
										${CStyles.transition_300} outline-none hover:border-[#ff772c] focus:border-[#ff772c]`}
							placeholder="Email"
							type="email"
							required
						/>
						<textarea
							className={`w-11/12 sm:w-3/5 p-3 border border-slate-900 rounded-xl bg-slate-700
										flex justify-between items-center overflow-hidden
										${CStyles.transition_300} outline-none hover:border-[#ff772c] focus:border-[#ff772c]`}
							placeholder="How can we help you?"
							type="text"
							rows={5}
							required
						/>
						<input
							type="submit"
							className={`cursor-pointer p-2 w-3/5 rounded border border-[#ff722c]
							bg-[#ff722c] hover:bg-white hover:text-[#ff722c]
							transition duration-300 ease-in
							px-5 py-3 text-white font-bold tracking-wide`}
						/>
					</form>
				</div>
				{/* </div> */}
			</div>

			<Footer />
		</>
	);
}

const styles = {
	icon: {
		margin: "0 10px",
		color: "#",
		transition: "color 0.3s ease-in-out",
	},
};
