import { CStyles } from '../constants/constants'

const TileAd = ({ adData }) => (
	<a
		className={`h-72 w-full p-3 flex items-center justify-center cursor-pointer hover:bg-slate-600 ${CStyles.transition_300}`}
		href={adData && adData.link}
		target="blank"
	>
		{
			adData ?
				(
					<div
						className="w-full h-full overflow-hidden bg-slate-700"
					>
						{
							adData.image &&
							<img
								src={adData.image}
								alt={adData.title}
								className="w-full h-full object-center"
							/>
						}
						{
							adData.video &&
							<video />
						}
					</div>
				) : (
					<img src='./images/ZedTour_Ad_Placeholder_NoBg.png' alt='Advertise with us' className='h-full object-contain object-center' />
				)}
	</a>
)

const BannerAd = ({ adData }) => (
	<a
		className={`w-full h-60 flex items-center justify-center cursor-pointer hover:bg-slate-600 ${CStyles.transition_300}`}
		href={adData && adData.link}
		target="blank"
	>
		{
			adData ?
				(
					<div
						className="w-full h-full overflow-hidden bg-slate-700 "
					>
						{
							adData.image &&
							<img
								src={adData.image}
								alt={adData.title}
								className="w-full h-full object-center"
							/>
						}
						{
							adData.video &&
							<video />
						}
					</div>
				) : (
					<img src='./images/ZedTour_Ad_Placeholder_NoBg.png' alt='Advertise with us' className='h-full object-contain object-center' />
				)}

	</a>
)

export { TileAd, BannerAd };
