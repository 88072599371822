import React, { useState } from 'react';
import NavigationBar from '../../../components/NavigationBar';
import { useParams, useSearchParams } from 'react-router-dom';
import BookingDetails from '../subRoutes/bookingDetails';
import BoookingListings from '../subRoutes/listings';
import { IRoutes } from '../../../constants/constants';

export default function BookingFrame({ children }) {
	const { table, column, value } = useParams();
	const [bookingParams] = useSearchParams();
	const [query] = useState(bookingParams.get('q' || ''));


	return (
		<>
			<NavigationBar pageName={IRoutes.booking.substring(1)} />
			<div
				className='w-screen h-screen pt-20 md:pt-24 overflow-x-hidden'
			>
				<div
					className='container h-full mx-auto py-5 px-10 max-md:px-5 flex flex-col gap-5'
				>
					{
						query
							? <BookingDetails table={table} column={column} category={value} entityId={query} />
							: <BoookingListings column={column} category={value} />
					}
				</div>
			</div>
		</>
	);
}