import React from 'react';

const GridCard = ({ title, subtitle, image, href, linkTarget }) => {

	return (
		<a
			target={linkTarget}
			href={href}
			className='min-w-60 max-h-56 flex flex-col gap-2 hover:bg-slate-200 
									transition-all duration-300 ease-in rounded-2xl'
		>
			<div
				className='w-full h-42'
			>
				<img
					alt={title}
					className={`object-cover rounded-xl w-full h-56 border border-white
												hover:border-[#ff722c]
												transition-all duration-300 ease-in
											`}
					loading='lazy'
					src={`${image}`}
					srcSet={`${image}?tr=w-400 400w, ${image}?tr=w-800 800w`}
				/>
			</div>
			<div
				className='w-full h-full flex flex-col'
			>
				<h2
					className={`capitalize text-lg font-semibold 
												hover:text-[#ff722c]
												transition-all duration-300 ease-in`
					}
				>
					{title}
				</h2>
				{
					subtitle
					&&
					<p
						className='text-sm text-slate-500'
					>
						{subtitle}
					</p>
				}
			</div>
		</a>
	);
}
const ActionGridCard = ({ title, subtitle, image, onClick }) => {

	return (
		<div
			className='min-w-60 max-h-56 flex flex-col gap-2 hover:bg-slate-200 
						cursor-pointer transition-all duration-300 ease-in rounded-2xl'
			onClick={onClick}
		>
			<div
				className='w-full h-42'
			>
				<img
					alt={title}
					className={`object-cover rounded-xl w-full h-56 border border-white
												hover:border-[#ff722c]
												transition-all duration-300 ease-in
											`}
					loading='lazy'
					src={`${image}`}
					srcSet={`${image}?tr=w-400 400w, ${image}?tr=w-800 800w`}
				/>
			</div>
			<div
				className='w-full h-full flex flex-col'
			>
				<h2
					className={`capitalize text-lg font-semibold 
												hover:text-[#ff722c]
												transition-all duration-300 ease-in`
					}
				>
					{title}
				</h2>
				{
					subtitle
					&&
					<p
						className='text-sm text-slate-500'
					>
						{subtitle}
					</p>
				}
			</div>
		</div>
	);
}

export { GridCard, ActionGridCard };