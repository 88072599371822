import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchHeaders, IRoutes, StorageKeys } from '../../constants/constants';
import { faFacebook, faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../components/CustomInputField';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../redux/reducers/user';
import Spinner from '../../components/spinner';
import { UserModel } from './models/userModel';

export default function UserAuth(params) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const mainContainerRef = useRef();

	const [isSignin, setIsSignin] = useState(true);
	const [isBusiness, setIsBusiness] = useState(false);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState('');
	const [role, setRole] = useState(['viewer']);


	const socials = [
		{
			platform: "facebook",
			link: "http://www.facebook.com"
		},
		{
			platform: "twitter",
			link: "http://www.twitter.com"
		},
		{
			platform: "instagram",
			link: "http://www.instagram.com"
		},
		{
			platform: "youtube",
			link: "http://www.youtube.com"
		}
	]

	const resetStates = () => {
		setEmail('');
		setPassword('');
		setUsername('');
		// setIsBusiness(false);
		setRole(['viewer']);
	}

	const pageScroll = (direction) => {
		const screenWidth = window.innerWidth;

		if (screenWidth > 780) return

		switch (direction) {
			case 'left':
				mainContainerRef.current.scrollBy({ left: -screenWidth, behavior: 'smooth' });
				break;
			case 'right':
				mainContainerRef.current.scrollBy({ left: screenWidth, behavior: 'smooth' });
				break;
			default:
				break;
		}
	}

	const signinHandler = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		if (!email && !password) {
			alert("Please provide valid user credentials.");
			return;
		}
		const data = {
			email: email,
			password: password
		}

		try {
			const res = await
				fetch(
					`${process.env.REACT_APP_API_URL}/user/signin`,
					{
						method: 'POST',
						headers: FetchHeaders,
						body: JSON.stringify(data)
					}
				);

			console.log(res)

			const loginCredentials = await res.json();

			if (loginCredentials && loginCredentials.ok) {
				dispatch(updateUser(loginCredentials.user));

				const timestamp = new Date().getTime();

				window.localStorage.setItem(
					StorageKeys.user,
					JSON.stringify({
						...loginCredentials.user,
						pass_hash: undefined,
						authToken: {
							...loginCredentials.user.authToken,
							createdOn: timestamp
						},
						refreshToken: {
							...loginCredentials.user.refreshToken,
							createdOn: timestamp
						}
					}));

				resetStates();

				navigate(IRoutes.home);
			}
			else {
				if (!loginCredentials)
					throw Error({ ok: false, error: "Unable to connect to Database." });

				throw loginCredentials;
			}

		} catch (error) {
			alert(error.error);
			console.log(error);
			setIsLoading(false);
		}
	}

	const signupHandler = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		if (!email && !password && !username) {
			alert("Please provide valid user credentials.");
			return;
		}

		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/user/signup`,
				{
					method: 'POST',
					headers: FetchHeaders,
					body: JSON.stringify(new UserModel({
						username: username,
						email: email,
						password: password,
						roles: role
					}))
				}
			);

			const signupResults = await res.json();

			if (signupResults && signupResults.ok) {
				resetStates();
				setIsLoading(false);
				pageScroll('left')
			}
			else {
				if (!signupResults)
					throw Error({ ok: false, error: "Unable to connect to Database." });

				throw signupResults;
			}

		} catch (error) {
			alert(error.error)
			setIsLoading(false);
		}
	}

	return (
		<>
			{isLoading && <Spinner />}
			<div
				className='w-screen max-sm:h-screen h-[200vh] md:h-screen flex justify-center items-center 
			bg-slate-800 overflow-hidden relative'
			>
				<div
					className='absolute w-[600px] h-[600px] bg-purple-900 transform 
					-translate-x-[500px] -translate-y-[200px] rounded-full blur-3xl opacity-5 z-0'
				/>

				<div
					className='absolute w-[600px] h-[600px] bg-blue-900 transform 
					translate-x-[500px] translate-y-[200px] rounded-full blur-2xl opacity-10 z-0'
				/>

				<div
					ref={mainContainerRef}
					className={`md:container mx-auto h-full z-10 relative overflow-x-hidden`}
				>

					<div
						className={`w-fit md:w-full h-full flex flex-row-reverse overflow-x-hidden md:grid md:grid-cols-2`}
					>
						<div
							className={`max-md:hidden w-1/2 h-full px-5 flex flex-col absolute transition-all duration-300 ease-in z-10
						${isSignin ? "inset-x-0" : "inset-x-1/2"}`}
						>
							<div
								className={`w-32 transition-all duration-300 ease-in transform 
							${!isSignin ? "translate-x-[350%]" : "translate-x-5"}`}
							>
								<a
									href={IRoutes.home}
								>
									<img
										src="logo512.png"
										alt="Logo"
										className='w-32'
									/>
								</a>
							</div>

							<div
								className='w-full'
							>
								<p
									className='text-4xl font-medium text-center tracking-wide flex flex-col my-12'
								>
									<span>Welcome to ZedTour,</span>
									<span>your go-to app</span>
									<span>for exploring</span>
									<span>the beauty and wonders of Zambia.</span>
								</p>
								<div
									className='w-full h-14 flex justify-center items-center gap-10'
								>
									{
										socials.map
											(
												(social, i) => {
													let icon;

													switch (social.platform) {
														case 'facebook':
															icon = faFacebook;
															break;
														case 'twitter':
															icon = faXTwitter;
															break;
														case 'instagram':
															icon = faInstagram;
															break;
														case 'youtube':
															icon = faYoutube;
															break;
														default:
															icon = faCancel;
															break;
													}

													return (
														<a
															key={i}
															target='black'
															href={social.link}
															className='text-3xl hover:text-[#ff722c] 
														transition-all duration-300 ease-in'
														>
															<FontAwesomeIcon icon={icon} />
														</a>
													)
												}

											)
									}
								</div>
							</div>
						</div>
						<div
							className={`w-screen md:w-full h-full flex justify-center relative overflow-x-hidden md:inset-x-0`}
						>
							<div
								className={`w-full md:w-full h-full flex justify-center items-center shadow-lg 
							transition-all duration-300 ease-in transform ${isSignin && "md:translate-x-[120%]"}`
								}
							>
								<div className='w-full h-full absolute z-0 bg-slate-900 opacity-50' />

								{/* Sign up form */}
								<form
									onSubmit={signupHandler}
									className='w-full sm:w-96 h-full flex flex-col justify-center items-center gap-5 p-5 z-10'
								>
									<h1
										className='text-4xl font-bold tracking-wider capitalize'
									>
										Sign Up
									</h1>
									<Input
										type='email'
										value={email}
										placeholder='Email'
										maxLength={60}
										required
										onChange={
											(e) =>
												setEmail(e.target.value.toLowerCase())
										}
									/>
									<Input
										type={'password'}
										placeholder={'Password'}
										value={password}
										required
										onChange={
											(e) =>
												setPassword(e.target.value)
										}
									/>
									<Input
										type='text'
										value={username}
										placeholder={isBusiness ? 'Business name' : 'Fullname'}
										required
										maxLength={30}
										onChange={
											(e) => setUsername(e.target.value)
										}
									/>
									<div
										className='w-full flex justify-between'
									>
										<p>Signing up as a business ?</p>
										<label for="role_yes">Yes</label>
										<input
											type='radio'
											name='role'
											id='role_yes'
											value={isBusiness}
											onChange={
												e => {
													setIsBusiness(true);
													setRole(
														[`${e.target.value && "editor"}`]
													);
												}
											}
										/>
										<label for="role_no">No</label>
										<input
											type='radio'
											name='role'
											id='role_no'
											defaultChecked
											onClick={
												e => {
													setIsBusiness(false);
													setRole(
														[`${e.target.value && "viewer"}`]
													);
												}
											}
										/>

									</div>

									<button
										type='submit'
										className={`w-full h-10 border border-[#ff722c] 
										bg-[#ff722c] hover:bg-white hover:text-[#ff722c] text-white
										rounded-xl outline-none
										transition-all duration-300 ease-in
										tracking-wide font-semibold
									`}
									>
										Sign Up
									</button>

									<div
										className='w-full'
									>
										<p
											className='text-center'
										>
											Already have an account? {" "}
											<span
												className='text-[#ff722c] cursor-pointer'
												onClick={
													() => {
														setIsSignin(!isSignin);
														pageScroll('left')
														resetStates();
													}
												}
											>
												Sign In
											</span>
											.
										</p>
									</div>
								</form>
							</div>
						</div>

						<div
							className={`w-screen md:w-full h-full flex md:justify-center relative overflow-x-hidden md:inset-x-0`}
						>
							<div
								className={`w-full md:w-full h-full flex justify-center items-center shadow-lg  
							transition-all duration-300 ease-in transform ${!isSignin ? "md:-translate-x-[120%] z-0" : "z-20"}`
								}
							>
								<div className='w-full h-full absolute z-0 bg-slate-900 opacity-50' />

								{/* Signin form */}
								<form
									onSubmit={signinHandler}
									className='w-full sm:w-96 h-full flex flex-col justify-center items-center gap-10 p-5 z-10'
								>
									<h1
										className='text-4xl font-bold tracking-wider capitalize'
									>
										Sign In
									</h1>

									<Input
										type='email'
										value={email}
										placeholder='Email'
										maxLength={60}
										required
										onChange={
											(e) =>
												setEmail(e.target.value.toLowerCase())
										}
									/>
									<Input
										type={'password'}
										placeholder={'Password'}
										value={password}
										required
										onChange={
											(e) =>
												setPassword(e.target.value)
										}
									/>
									<button
										type='submit'
										className={`w-full h-10 border border-[#ff722c] 
										bg-[#ff722c] hover:bg-white hover:text-[#ff722c] text-white
										rounded-xl outline-none
										transition-all duration-300 ease-in
										tracking-wide font-semibold
							`}
									>
										Sign In
									</button>
									<div
										className='w-full'
									>
										<p
											className='text-center'
										>
											New to the platform? {" "}
											<span
												className='text-[#ff722c] cursor-pointer'
												onClick={
													() => {
														setIsSignin(!isSignin);
														pageScroll('right');
														resetStates();
													}
												}
											>
												Sign Up

											</span>
											.
										</p>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}