import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FetchHeaders, IAuthRoutes, IRoutes, StorageKeys } from '../../constants/constants';
import NavigationBar from '../../components/NavigationBar';
import Spinner from '../../components/spinner';
import Itinerary from './components/itinerary';
import OptionsComponent from './components/options';
import Memories from './components/memories';
import Story from './components/story';
import ManageAcc from './components/manageAcc';
import BusinessForm from '../manage_bus/components/businessSetup'

export default function Profile(params) {
	const navigate = useNavigate();

	const user = useSelector(state => state.user.user);
	const [isLoading, setIsLoading] = useState(true);
	const [isSignOut, setIsSignOut] = useState(false);
	const [optionIndex, setOptionIndex] = useState(0);

	const signoutHandler = async () => {
		setIsLoading(true);
		setIsSignOut(false)

		await
			fetch(
				`${process.env.REACT_APP_API_URL}/user/signout`,
				{
					method: 'POST',
					headers: {
						"authorization": `Bearer ${user.authToken.token}`,
						...FetchHeaders
					},
					body: JSON.stringify({ email: user.email })
				}
			)
				.then((res) => {
					// console.log(res);
					if (res.status === 200) {
						console.log('user signed out.')

						window.localStorage.removeItem(StorageKeys.user);
						setTimeout(() => navigate(IRoutes.home), 500)

					}
					setTimeout(() =>
						setIsLoading(false),
						2000
					)
				})
				.catch(error => {
					console.log(error);

					alert(error);
				});
	};

	useEffect(() => {
		const checkSignedIn = async () => {
			const user = window.localStorage.getItem(StorageKeys.user);

			if (!user)
				navigate(IAuthRoutes.user)
			else
				setTimeout(() => setIsLoading(false), 1500)
		};

		checkSignedIn();

	}, [navigate]);

	const optionPages = [<Itinerary />, <Memories />, <Story />, <ManageAcc />,];

	return (
		<>
			{isLoading && <Spinner />}

			<NavigationBar />

			{/* Signout model */}
			<div
				className={`absolute inset-0 h-screen w-screen backdrop-brightness-75
					transition-all duration-300 ease-in-out px-5
					${isSignOut ? 'z-10 scale-100 opacity-100' : '-z-10 scale-95 opacity-0'}
					flex justify-center items-center
				`}
				onClick={() => setIsSignOut(false)}
			>
				<div
					className='w-96 h-72 flex flex-col gap-10 bg-slate-700 rounded-2xl
						justify-center items-center'
					onClick={(event) => event.stopPropagation()}
				>
					<h1 className='text-2xl text-white font-semibold'>Are you sure?</h1>
					<div
						className='w-full flex justify-evenly'
					>
						<button
							className='min-w-32 h-11 rounded-2xl bg-red-400 
							capitalize flex items-center justify-center hover:text-white
							hover:bg-red-500 transition duration-300 ease-in-out'

							onClick={signoutHandler}
						>
							yes
						</button>
						<button
							className='min-w-32 h-11 rounded-2xl bg-[#ff722cbc] 
						capitalize flex items-center justify-center hover:text-white
							hover:bg-[#ff722c] transition duration-300 ease-in-out'
							onClick={() => setIsSignOut(false)}
						>
							no
						</button>
					</div>
				</div>
			</div >

			<div
				className='w-screen h-screen pt-20 md:pt-24 overflow-x-hidden'
			>
				<div
					className='container mx-auto px-2 md:px-10 flex flex-col justify-center gap-5 py-5'
				>
					<OptionsComponent
						onSignOut={() => setIsSignOut(true)}
						optionIndex={optionIndex}
						setOptionIndex={setOptionIndex}
					/>

					<div
						className='w-full flex-grow min-h-96 bg-slate-800 rounded-2xl flex p-1 overflow-hidden'
					>
						{
							user && user.roles.includes('editor')
								? (
									<									>
										<BusinessForm />
									</>)
								: (<>
									{optionPages.map((page, i) => (
										<div className={`min-w-full h-fit 
						transition-all duration-500 ease-in-out transform
						${optionIndex === 0 ?
												'' : optionIndex === 1 ?
													'-translate-x-full' : optionIndex === 2 ?
														'-translate-x-[200%]' : '-translate-x-[300%]'}`}>
											{page}
										</div>
									))}
								</>
								)}
					</div>
				</div>

			</div >

		</>
	);
}