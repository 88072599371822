import React from "react"
import { IAssetURLs } from "../../../constants/constants"
import ScrollableListContainer from "../../../components/scrollableListContainer"
import { GridCard } from "../../../components/gridCard"


const TransportationComponent = () => {
	const carRentals = [
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		},
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		},
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		},
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		},
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		},
		{
			label: 'Car Rental Company',
			image: `${IAssetURLs.imagekitBucket}/rentalplaceholder.jpg`,
			url: '#'
		}]
	const taxiUber = [
		{
			label: 'PickMe',
			image: `${IAssetURLs.imagekitBucket}/pickme.jpg`,
			url: 'https://play.google.com/store/apps/details?id=com.arrantapps.pickme'
		},
		{
			label: 'Yango',
			image: `${IAssetURLs.imagekitBucket}/yango1.jpg`,
			url: 'https://play.google.com/store/apps/details?id=com.yandex.yango'
		},
		{
			label: 'Unka go',
			image: `${IAssetURLs.imagekitBucket}/unkago.jpg`,
			url: 'https://play.google.com/store/apps/details?id=com.probase.unka'
		},
		{
			label: 'GO',
			image: `${IAssetURLs.imagekitBucket}/go.jpg`,
			url: 'https://play.google.com/store/apps/details?id=com.multibrains.taxi.passenger.ulendotaxizambia'
		},
		{
			label: 'Bolt',
			image: `${IAssetURLs.imagekitBucket}/bolt.jpg`,
			url: 'https://play.google.com/store/apps/details?id=ee.mtakso.driver'
		},
		{
			label: 'ZamCab',
			image: `${IAssetURLs.imagekitBucket}/zamcab.jpg`,
			url: 'https://play.google.com/store/apps/details?id=zamcab.taxi.passenger'
		},
		{
			label: 'My Taxi Zambia',
			image: `${IAssetURLs.imagekitBucket}/mytaxi.jpg`,
			url: 'https://play.google.com/store/apps/details?id=com.mytaxizambia.customer'
		}]

	return (
		<div
			className={`min-w-full h-full transform transition-all duration-500 delay-100 ease-in-out px-5 sm:px-8 md:px-7`}
		>
			{/* <ScrollableListContainer
				title={"Car Rentals"}
			> 	{
					carRentals.map(
						(item, i) =>
						(
							<GridCard
								key={i}
								href={item.url}
								image={item.image}
								title={item.label}
								subtitle={item.subtitle}
							/>
						)
					)
				}</ScrollableListContainer> */}
			<ScrollableListContainer
				title={"Taxi / Uber"}
			> 	{
					taxiUber.map(
						(item, i) =>
						(
							<GridCard
								key={i}
								href={item.url}
								target={'blank'}
								image={item.image}
								title={item.label}
								subtitle={item.subtitle}
							/>
						)
					)
				}</ScrollableListContainer>
		</div>
	)
}

export { TransportationComponent }