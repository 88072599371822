import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Card = ({ data_object, onClick }) => {
	const imageRef = useRef();

	const HandleMouseEnter = () => {
		imageRef.current.style.transform = "scale(1.1)";
	}

	const HandleMouseLeave = () => {
		imageRef.current.style.transform = "scale(1)";
	}

	return (
		<div
			className="w-full h-80 bg-slate-600 overflow-hidden flex justify-center relative cursor-pointer"
			onMouseEnter={() => HandleMouseEnter()}
			onMouseLeave={() => HandleMouseLeave()}
			onClick={onClick}
		>
			<img
				ref={imageRef}
				src={(data_object && data_object.image) || './images/no_image_b.png'}
				loading="lazy"
				alt=''
				className='absolute h-full w-full object-cover object-center z-0 transition-all duration-300
					ease-in-out transform'
			/>
			{
				data_object &&
				(
					<div
						className='self-end w-full h-1/2 bg-gradient-to-t from-black to-transparent z-10 
					flex flex-col justify-end p-5 gap-2 text-white'
					>
						<h3
							className='text-2xl tracking-wider font-semibold'
						>
							{data_object.title}
						</h3>

						<p>{data_object.brief}</p>
					</div>

				)
			}
		</div>
	);
}

const MiniCard = ({ data_object, onClick }) => {
	const imageRef = useRef();

	const HandleMouseEnter = () => {
		imageRef.current.style.transform = "scale(1.1)";
	}

	const HandleMouseLeave = () => {
		imageRef.current.style.transform = "scale(1)";
	}

	return (
		<div
			className="w-full h-full bg-slate-600 overflow-hidden flex justify-center relative cursor-pointer"
			onMouseEnter={() => HandleMouseEnter()}
			onMouseLeave={() => HandleMouseLeave()}
			onClick={onClick}
		>
			<img
				ref={imageRef}
				src={data_object ? data_object.image : '/images/no_image_b.png'}
				alt=''
				className='absolute h-full w-full object-cover object-center z-0 transition-all duration-300
					ease-in-out transform'
			/>
			{
				data_object &&
				(
					<div
						className='self-end w-full h-1/2 bg-gradient-to-t from-black to-transparent z-10 
					flex flex-col justify-end p-5 gap-2 text-white'
					>

						<h3
							className='text-2xl tracking-wider font-semibold'
						>
							{data_object.title}
						</h3>

					</div>
				)
			}
		</div>
	);
}

const ItemCard = ({ item, url }) => {
	return (
		<div
			className='h-60 md:h-72 shadow rounded-2xl bg-slate-800'
			onClick={e => e.stopPropagation()}
		>
			<a
				href={url}
				className='md:min-w-60 max-h-56 flex flex-col gap-1 hover:bg-slate-600 
						transition-all duration-300 ease-in rounded-t-xl'
			>
				<div
					className='w-full h-36 md:h-56'
				>
					<img
						alt={item.business_name}
						className={`object-cover rounded-t-xl w-full h-36 md:h-56
															hover:border-[#ff722c]
															transition-all duration-300 ease-in
														`}
						loading='lazy'
						src={`${item.image}`}
						srcSet={`${item.image}?tr=w-400 400w, ${item.image}?tr=w-800 800w`}
					/>
				</div>
				<div
					className='w-full h-full flex flex-col px-3'
				>
					<h2
						className={`capitalize md:text-lg font-semibold 
																				hover:text-[#ff722c] flex justify-between
																				transition-all duration-300 ease-in`
						}
					>
						{item.business_name}
						<span
							className='md:text-xl font-bold flex sm:items-center gap-2'
						>
							<FontAwesomeIcon
								icon={faStar}
								className='md:text-md max-sm:pt-1 text-orange-600' />
							{item.rating}
						</span>
					</h2>

					<p
						className='text-sm text-slate-500 capitalize'
					>
						{item.location && item.location.town},{" "}
						{item.location && item.location.province}
					</p>
				</div>
			</a>
		</div>
	)
}

export { Card, MiniCard, ItemCard };