import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import NavigationBar from '../../components/NavigationBar'
import { CStyles, IAssetURLs, IRoutes } from '../../constants/constants'
import Footer from '../../components/footer'


// Sample tour data
const initialToursData = [
	{
		id: 1,
		name: 'City Sightseeing Tour',
		description: 'Explore the city\'s landmarks and attractions on this guided tour.',
		price: '$50',
		duration: '3 hours',
		image: `${IAssetURLs.imagekitBucket}/lusaka.jpg`
	},
	{
		id: 2,
		name: 'Nature Adventure Trek',
		description: 'Embark on an exciting trek through the wilderness and discover breathtaking views.',
		price: '$80',
		duration: '5 hours',
		image: ''
	},
	// Add more tour objects as needed
]

export default function Tours() {
	const navigate = useNavigate()
	const [toursData, setToursData] = useState([])
	const [filteredTours, setFilteredTours] = useState([])
	const [searchTerm, setSearchTerm] = useState('')

	const fetchTours = useCallback(async () => {
		// await fetch().then().catch()
	}, [])

	useEffect(() => {
		// Simulating API call to fetch tour data
		// Replace this with actual API call in a real-world scenario
		setTimeout(() => {
			setToursData(initialToursData)
			setFilteredTours(initialToursData)
		}, 1000)
	}, [])

	// Function to handle search input change
	const handleSearchChange = (event) => {
		const value = event.target.value
		setSearchTerm(value)
		const filtered = toursData.filter(tour =>
			tour.name.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredTours(filtered)
	}

	// Function to book a tour
	const bookTour = (tourId) => {
		//TODO: add booking form 
		//TODO: send booking to db 
		navigate(IRoutes.booking)
	}

	return (
		<>
			<NavigationBar pageName={IRoutes.tours.substring(1)} />
			<div className='w-screen min-h-screen pt-20 md:pt-28 flex flex-col justify-center items-center text-center'>
				<div
					className='container mx-auto h-fit flex flex-col items-center px-10'
				>
					<div
						className='w-full h-80 md:h-52 flex flex-col items-center justify-center'
					>
						<h1 className='text-4xl md:text-6xl text-slate-400 mb-8'>Explore Our Tours</h1>
						<div className='w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mb-8'>
							<input
								type="text"
								placeholder="Search tours..."
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
								value={searchTerm}
								onChange={handleSearchChange}
							/>
						</div>
					</div>
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-5'>
						{filteredTours.map(tour => (
							<div key={tour.id} className='bg-slate-800 rounded-lg p-4 shadow-md'>
								<img
									src={tour.image || './images/no_image_b.png'}
									alt={tour.name}
									className='w-full h-48 object-cover mb-4 rounded-md'
								/>
								<h2
									className='text-xl font-semibold mb-2'
								>
									{tour.name}
								</h2>
								<p
									className='text-slate-300 mb-2'
								>
									{tour.description}
								</p>
								<p
									className='text-slate-100'
								>
									Price: <span className='font-semibold text-lg'>{tour.price}</span>
								</p>
								<p
									className='text-slate-100 mb-2'
								>
									Duration: <span className='font-semibold text-lg'>{tour.duration}</span>
								</p>
								<button
									onClick={() => bookTour(tour.id)}
									className={`bg-[#ff772c] text-white px-4 py-2 w-full rounded-md hover:scale-[1.02]
									${CStyles.transition_300}`}
								>
									Book Now
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}