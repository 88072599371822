import { LocationModel } from "../../../models/locationModel";

/**
 * @param {string} event_id
 * @param {string} event_title
 * @param {string} start_date
 * @param {string} end_date
 * @param {string} brief_bio
 * @param {string} about
 * @param {string} host
 * @param {LocationModel} location
 */

class Event {
	constructor({
		event_id,
		event_title,
		start_date,
		end_date,
		brief,
		image,
		about,
		host,
		location
	}) {
		this.event_id = event_id ?? '';
		this.event_title = event_title ?? '';
		this.start_date = start_date ?? '';
		this.end_date = end_date ?? '';
		this.brief = brief ?? '';
		this.image = image || './images/no_image_b.png';
		this.about = about ?? '';
		this.host = host ?? '';
		this.location = location ?? new LocationModel({ street: '', town: '', province: '' });
	};

	toCard() {
		return ({
			id: this.event_id,
			title: this.event_title,
			date: this.start_date,
			brief: this.brief,
			image: this.image,
			about: this.about,
			host: this.host,
			location: this.location
		});
	};

	getEvents() {
	}
}

export { Event };