import React from 'react';

export default function Memories(params) {

	return (
		<div className='min-w-full h-full p-2 grid grid-cols- gap-2 items-center justify-center'
		>
			<h1
				className='text-4xl md:text-6xl text-slate-400'
			>
				Under construction
			</h1>
		</div>
	);
}