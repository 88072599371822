import React, { useEffect, useState } from 'react';
import { Input, Select } from '../../../components/CustomInputField';
import { StyledButton } from '../../../components/customButton';
import { FetchHeaders } from '../../../constants/constants';
import { Accommodation } from '../models/accommodationModel';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faX } from '@fortawesome/free-solid-svg-icons';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../firebase';
import { Attraction } from '../models/attractionsModel';

const BusinessForm = () => {
	const user = useSelector(state => state.user.user);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [businessType, setBusinessType] = useState('');
	const [businessImage, setBusinessImage] = useState();
	const [businessImageURL, setBusinessImageURL] = useState();
	const [serviceType, setServiceType] = useState('');
	const [about, setAbout] = useState('');
	const [location, setLocation] = useState({
		street: '',
		town: '',
		province: ''
	});
	const [roomImages, setRoomImages] = useState([{ name: '' }])
	const [rooms, setRooms] = useState([
		{
			roomClass: '',
			roomImage: '',
			amenities: '',
			pricePerNight: 0
		},
	]);

	const uploadImage = async (index = null, image) =>
		new Promise(
			(resolve) => {
				const userImagesRef = ref(storage, `userImages/${user.business_id ?? user.uid}${index !== null ? `_${index}` : ''}`)
				uploadBytes(userImagesRef, image)
					.then(async (e) => {
						const imageURL = await getDownloadURL(e.ref);
						resolve(imageURL);
					})
					.catch(error => {
						console.log(error);
						resolve(error);
					});
			}
		)

	const handleRoomChange = (index, event) => {
		const { name, value } = event.target;
		const updatedRooms = [...rooms];
		updatedRooms[index][name] = value;
		setRooms(updatedRooms);
	};

	const handleImageChange = async (index, event) => {
		const file = event.target.files[0];

		if (file) {
			const updatedRoomImages = [...roomImages];
			updatedRoomImages[index] = file;

			setRoomImages(updatedRoomImages)

			const imageURL = await uploadImage(index, file);

			const updatedRooms = [...rooms];
			updatedRooms[index].roomImage = imageURL;
			setRooms(updatedRooms);
		}
	};

	const addRoom = () => {
		setRooms([...rooms, { roomClass: '', roomImage: '', amenities: '', pricePerNight: 0 }]);
		setRoomImages([...roomImages, { name: '' }]);
	};

	const postAccommodation = async () => {

		await fetch(
			`${process.env.REACT_APP_API_URL}/accommodations`,
			{
				method: 'POST',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
				body: JSON.stringify(new Accommodation({
					business_name: name,
					business_id: user.business_id,
					email: email,
					about: about,
					phone: phone,
					image: businessImageURL,
					type: serviceType,
					location: location,
					rooms: rooms
				}))
			})
			.then((res) => console.log(res))
			.catch((error) => console.log(error));
	};

	const postAttraction = async () => {
		await fetch(
			`${process.env.REACT_APP_API_URL}/attractions`,
			{
				method: 'POST',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
				body: JSON.stringify(new Attraction({
					business_name: name,
					business_id: user.business_id,
					email: email,
					about: about,
					phone: phone,
					image: businessImageURL,
					category: businessType,
					type: serviceType,
					location: location,
				}))
			})
			.then((res) => console.log(res))
			.catch((error) => console.log(error));
	};

	const postTransportation = () => { };
	const postVendor = () => { };

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (phone.length < 11) {
			alert('phone number invalid.')
			return;
		}

		switch (businessType) {
			case 'accommodation':
				postAccommodation();
				break;

			case 'heritage_site':
				postAttraction();
				break;

			case 'transportation_agency':
				postTransportation();
				break;

			case 'vendor':
				postVendor();
				break;

			default: break;
		}

	};

	useEffect(() => {
		console.log(user)
		setName(user.username);
		setEmail(user.email);
		setPhone(user.phone)
	}, [user])

	return (
		<form className="flex flex-col p-5 items-start" onSubmit={handleSubmit}>
			<h1 className='text-xl my-3'>Setup Your Business</h1>
			<hr className='w-full h-1 mb-2' />
			<h2 className='text-lg mb-4'>Business details</h2>
			<div className='w-full grid grid-cols-2 gap-5 my-2'>
				<div className="flex gap-2 items-center">
					<label className="mb-1 text-nowrap">Business Name:</label>
					<Input
						value={name}
						placeholder={'Name of the business'}
						onChange={e => setName(e.target.value)}
						required
					/>
				</div>
				<div className="flex gap-2 items-center">
					<label className="mb-1 text-nowrap">Business email:</label>
					<Input
						value={email}
						placeholder={'Business email'}
						onChange={e => setEmail(e.target.value)}
						required
					/>
				</div>
				<div className="flex gap-2 items-center">
					<label className="mb-1 text-nowrap">Business Type:</label>
					<Select
						value={businessType}
						onChange={(e) => setBusinessType(e.target.value)}
						className='text-center'
						required
					>
						<option value={""}>--- Select type ---</option>
						<option value={'accommodation'}>Accommodation</option>
						<option value={'attraction'}>Attraction</option>
						<option value={'transportation'}>Transportation</option>
						<option value={'vendor'}>Vendor</option>
					</Select>
				</div>

				<div className="flex gap-2 items-center">
					<label className="mb-1 text-nowrap">Service Type:</label>
					<Select
						businessType onChange={(e) => setServiceType(e.target.value)}
						className='text-center'
						// disabled={businessType === 'accommodations' ? false : true}
						required
					>
						<option value={""}>--- Select type ---</option>
						{businessType === 'accommodation' &&
							(
								<>
									<option value={'hotel'}>Hotel</option>
									<option value={'motel'}>Motel</option>
									<option value={'lodge'}>Lodge</option>
									<option value={'inn'}>Inn</option>
									<option value={'resort'}>Resort</option>
									<option value={'guesthouse'}>Guest House</option>
								</>
							)
						}

						{businessType === 'attraction' &&
							(
								<>
									<option value={'national_park'}>National Park</option>
									<option value={'wildlife_reserve'}>Wildlife Reserve</option>
									<option value={'museum'}>Museum</option>
									<option value={'cultural_building'}>Cultural Building and Site</option>
									<option value={'heritage_house_and_building'}>Heritage House and Building</option>
									<option value={'monument'}>Monument</option>
									<option value={'historical_and_archaeological_site'}>Historical and Archaeological Site</option>
									<option value={'waterfall'}>Waterfall</option>
									<option value={'forest'}>Forest</option>
									<option value={'fossil_sites'}>Fossil Site</option>
									<option value={'rock_art_site'}>Rock Art Site</option>
								</>
							)
						}

						{businessType === 'transportation' &&
							(
								<>
									<option value={'car_rental'}>Car Rental</option>
									<option value={'tour_bus'}>Tour Bus</option>
									<option value={'drive'}>Driver</option>
								</>
							)
						}

						{businessType === 'vendor' &&
							(
								<>
									<option value={'art_vendor'}>Art Vendor</option>
									<option value={'food_vendor'}>Food Vendor</option>
									<option value={'clothing_vendor'}>Clothing Vendor</option>
									<option value={'souvenir_vendor'}>Souvenir Vendor</option>
								</>
							)
						}

					</Select>
				</div>

				<div className="flex gap-2 my-4 items-center">
					<label className="mb-1">Location:</label>
					<Input
						type="text"
						placeholder="Street"
						value={location.street}
						onChange={(e) => setLocation({ ...location, street: e.target.value })}
					/>
					<Input
						type="text"
						placeholder="Town"
						value={location.town}
						onChange={(e) => setLocation({ ...location, town: e.target.value })}
						required
					/>
					<Select
						value={location.province}
						onChange={(e) => setLocation({ ...location, province: e.target.value })}
						className={'text-center'}
						required
					>
						<option value={''}>--- Province ---</option>
						<option value={'central'}>Central</option>
						<option value={'copperbelt'}>Copperbelt</option>
						<option value={'eastern'}>Eastern</option>
						<option value={'luapula'}>Luapula</option>
						<option value={'lusaka'}>Lusaka</option>
						<option value={'muchinga'}>Muchinga</option>
						<option value={'northern'}>Northern</option>
						<option value={'northWestern'}>North Western</option>
						<option value={'southern'}>Southern</option>
						<option value={'western'}>Western</option>
					</Select>
				</div>
				<div className="flex gap-2 items-center">
					<label className="mb-1 text-nowrap">Phone:</label>
					<Input
						value={phone}
						placeholder='2600000000000'
						maxLength={12}
						minLength={10}
						onChange={e => {
							const value = e.target.value;
							setPhone(value);
						}}
						required
					/>
				</div>
			</div >

			<div
				className="flex w-full gap-2 mb-4"
			>

				<div
					className="w-full flex flex-col justify-start items-center gap-2"
				>
					<label
						className="self-start"
					>
						About:
					</label>
					<textarea
						className="w-full border border-slate-900 rounded-xl bg-slate-700
							flex justify-between items-center overflow-hidden
							transition duration-300 ease-in p-2 outline-none
							hover:border-[#ff722c]"
						value={about}
						rows={5}
						placeholder='Say something about your business.'
						onChange={(e) => setAbout(e.target.value)}
						required
					/>
				</div>
			</div>
			{businessType === 'accommodation' &&
				<div className='w-full flex flex-col gap-2 items-start'>
					<h1>Rooms</h1>

					{rooms.map((room, index) =>
					(
						<div
							key={index}
							className="w-full flex flex-col items-center gap-2 rounded-2xl border border-slate-600
									overflow-hidden"
						>
							<div
								className="col-span-2 w-full h-12 flex justify-center items-center bg-slate-600 relative px-5"
							>
								<h1>
									Room {index + 1}
								</h1>
								<div
									className='w-10 h-10 right-2 flex items-center justify-center 
									hover:bg-red-600 absolute rounded-full transition-all duration-300 ease-in-out
									cursor-pointer'
									onClick={() => {
										if (rooms.length > 1) {
											setRooms(rooms.filter((_, i) => i !== index));
											setRoomImages(roomImages.filter((_, i) => i !== index));
										}
									}}
								>
									<FontAwesomeIcon icon={faX} />
								</div>
							</div>
							<div className='w-full grid grid-cols-2 gap-2 p-5'>

								<label htmlFor='roomClass'>
									<p
										className='w-full text-left'
									>
										Room class:
									</p>
									<Input
										type="text"
										placeholder="eg. Presidential, economy, etc"
										name="roomClass"
										value={room.roomClass}
										onChange={(e) => handleRoomChange(index, e)}
										required
									/>
								</label>

								<label htmlFor='amenities'>
									<p
										className='w-full text-left'
									>
										Room amenities:
									</p>
									<Input
										type="text"
										placeholder="eg. 2 bed, mini fridge, etc"
										name="amenities"
										value={room.amenities}
										onChange={(e) => handleRoomChange(index, e)}
									/>
								</label>

								<label htmlFor={`roomImage_${index}`}>
									<p
										className='w-full text-left'
									>
										Room Image
									</p>
									<Input
										prefix={
											<div className='w-full h-full flex items-center bg-slate-700'>
												<FontAwesomeIcon icon={faCamera} className='w-10 text-2xl' />
												{roomImages[index].name ?? 'No images selected'}
											</div>}
										type="file"
										name='roomImage'
										id={`roomImage_${index}`}
										onChange={(e) => { handleImageChange(index, e) }}
										bg={'bg-white'}
										accept={"image/*"}
									/>
								</label>

								<label htmlFor='pricePerNight'>
									<p
										className='w-full text-left'
									>
										Price per night:
									</p>
									<Input
										name="pricePerNight"
										type='number'
										value={room.pricePerNight}
										onChange={(e) => {
											handleRoomChange(index, e)
										}}
										required
									/>
								</label>
							</div>
						</div>
					)
					)}
					<button
						type="button"
						className="rounded-xl h-10 w-32 text-white bg-blue-500 p-2 my-4"
						onClick={addRoom}
					>
						Add Room
					</button>
				</div>
			}
			<div className="flex flex-col items-start w-full my-4">
				<label className="mb-1 w-1/4">
					<p className='text-left mb-2'>
						Upload Establishment Pic:
					</p>
					<Input
						prefix={
							<div className='w-full h-full flex items-center bg-slate-700'>
								<FontAwesomeIcon icon={faCamera} className='w-10 text-2xl' />
								{businessImage ? businessImage.name ?? 'No images selected' : null}
							</div>}
						type="file"
						onChange={async (e) => {
							const image = e.target.files[0];
							setBusinessImage(image);

							const imageURL = await uploadImage(null, image)
							console.log(imageURL)
							setBusinessImageURL(imageURL)
						}}
						bg={'bg-white'}
						accept={"image/*"}
					/>

				</label>
			</div>
			<StyledButton type="submit" title={'Submit'} className={'w-52'} />
		</form>
	);
};

export default BusinessForm;
