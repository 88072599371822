import React, { useCallback, useEffect, useState } from 'react'
import NavigationBar from '../../components/NavigationBar'
import { FetchHeaders, IRoutes } from '../../constants/constants'
import Footer from '../../components/footer'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SearchItemComponent from './components/searchItemComponent'
import { SubNavWithSearch } from '../../components/subNavBar'
import Lodging from './components/lodging'
import { TransportationComponent } from './components/transportationComponent'
import AttractionsComponent from './components/attractionsComponent'
import { NothingToShow } from '../../components/nothingtoshow'
import { BannerAd } from '../../components/adContainer'


export default function Booking() {
	const categories = [
		{
			title: 'lodgings',
			action: () => setSearchParams({ category: 'lodgings' })
		},
		{
			title: 'transportation',
			action: () => setSearchParams({ category: 'transportation' })
		},
		{
			title: 'attractions',
			action: () => setSearchParams({ category: 'attractions' })
		},
	]
	const searchSuggestions = ['lusaka', 'chipata', 'livingstone']

	const user = useSelector(state => state.user.user)
	const [searchParams, setSearchParams] = useSearchParams()
	const [searchData, setSearchData] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const searchCategory = searchParams.get('category')
	const searchLocation = searchParams.get('location')

	const fetchSearch = useCallback(async () => {
		await fetch(
			`${process.env.REACT_APP_API_URL}/accommodations/location/${searchLocation}`,
			{
				method: 'GET',
				headers: {
					"authorization": `Bearer ${user.authToken.token}`,
					...FetchHeaders
				},
			})
			.then(async res => {
				const resData = await res.json()

				if (!resData) return

				console.log('Data fetched.')

				setSearchData(resData.data)

				setTimeout(() => setIsLoading(false), 2000)
			})
			.catch(error => {
				console.log(error)
				setTimeout(() => setIsLoading(false), 2000)
			})
	}, [searchLocation, user.authToken.token])

	useEffect(() => {
		fetchSearch()
	}, [fetchSearch])

	const ContentRender = () => {
		switch (searchCategory) {
			case 'lodgings':
				return <Lodging />
			case 'transportation':
				return <TransportationComponent />
			case 'attractions':
				return <AttractionsComponent />
			default:
				return <Lodging />
		}
	}

	return (
		<>
			<NavigationBar pageName={IRoutes.booking.substring(1)} />
			<div
				className='w-screen h-fit pt-20 md:pt-24 overflow-x-hidden'
			>
				<SubNavWithSearch
					options={categories}
					searchPlaceholder={'Search by location'}
					searchSuggestions={searchSuggestions}
				/>

				<div
					className='container bg-slate-5 mx-auto py-5 px-10 max-md:px-5 flex flex-col gap-10'
				>
					<BannerAd />
					{
						isLoading ?
							<div
								className='min-w-full h-full grid max-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-2'
							>
								{Array.apply(null, Array(8)).map((_, i) => (
									<div
										key={i}
										className='h-56 animate-pulse bg-slate-600 rounded-2xl'
									/>
								))}
							</div>
							: searchParams.get('location') ?
								<div
									className={`w-full h-full 
									${searchData.length > 0 && 'grid max-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-2'}`}
								>
									{
										searchData.length > 0 ?
											searchData.map(
												(item, i) => (
													<SearchItemComponent
														key={i}
														item={item}
													/>
												)
											) : (
												<NothingToShow />
											)
									}
								</div>
								:
								<div
									className='w-screen sm:w-[88vw] md:w-[92vw] self-center flex overflow-hidden'
								>
									{
										categories && <ContentRender />
									}
								</div>
					}
				</div>
			</div >
			<Footer />
		</>
	)
}