import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function OptionsComponent({ onSignOut, optionIndex, setOptionIndex }) {
	const user = useSelector(state => state.user.user);
	let options = [];

	switch (user.roles) {
		case 'editor':
			options = [
				'Manage Account',
				'signout'];
			break;
		case 'viewer':
			options = [
				'My Itinerary',
				'My Memories',
				'My Story',
				'Manage Account',
				'signout'
			];
			break;
		default: break;
	}


	return (
		<div className='w-full flex-grow md:h-16 rounded-2xl bg-slate-800 flex flex-wrap items-center overflow-x-hidden'>
			{
				options.map(
					(option, i) => {
						let isActive = i === optionIndex ?? true;

						return (
							<div
								key={i}
								className={`w-32 h-full flex justify-center items-center flex-grow 
								 	transition-all duration-500 ease-in-out
									cursor-pointer font-semibold
									${i !== options.length - 1
										? `md:after:h-10 after:border-e-2 after:border-slate-300 hover:bg-slate-600`
										: 'hover:bg-red-400 '}
									${isActive && "text-[#ff772c]"}
									${isActive && option === 'signout' && 'bg-red-400'}
								`}
								onClick={
									() => {
										option !== 'signout' && setOptionIndex(i);
										option === 'signout' && onSignOut();
									}
								}
							>
								<p
									className={`w-full h-10	flex justify-center items-center capitalize`}
								>
									{option}
								</p>
							</div>
						)
					}
				)
			}
		</div>

	);
}