class BookingModel {
	constructor({
		business_id,
		user_id,
		name,
		email,
		phone,
		rooms,
		check_in,
		check_out,
		special_request
	}) {
		this.business_id = business_id ?? '';
		this.user_id = user_id ?? '';
		this.name = name ?? '';
		this.email = email ?? '';
		this.phone = phone ?? '';
		this.rooms = rooms ?? [];
		this.check_in = check_in ?? '';
		this.check_out = check_out ?? '';
		this.special_request = special_request ?? '';
	}
}

export { BookingModel };