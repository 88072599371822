import React from "react";
import { IRoutes, IAssetURLs, CStyles } from "../../../constants/constants";

export default function HeroSection({ user }) {
	return (
		<section
			className="w-full h-screen sm:h-[200vh] md:h-screen relative flex justify-center items-center"
		>
			<div
				className="w-screen h-full bg-black opacity-25 md:opacity-50 absolute z-10"
			/>
			<img
				src={`${IAssetURLs.imagekitBucket}/victoriafalls.jpg`}
				alt="victoria falls"
				srcSet={`${IAssetURLs.imagekitBucket}/victoriafalls.jpg?tr=w-400 400w, 
					${IAssetURLs.imagekitBucket}/victoriafalls.jpg?tr=w-800 800w`}
				className="absolute object-cover h-full w-full z-0"
				loading="lazy"
			/>

			<div
				className="container h-full grid max-md:grid-rows-1 md:grid-cols-3 z-20 pt-24 px-10"
			>
				<div
					className="max-md:row-span-1 md:col-span-2 h-full w-full flex flex-col justify-center items-center"
				>
					<p
						className="text-5xl md:text-6xl font-bold flex flex-col gap-2 text-center
							md:text-left md:text-blue-100 text-white tracking-wider md:leading-[5rem]"
					>
						Pioneering Local Tourism in Zambia <br />
						<q
							className={`text-sm py-4 md:text-2xl text-[#ff722c]`}
						>
							Easy, Convenient, Personalized
						</q>
					</p>
					<div
						className="w-full md:w-full h-1/3 py-10 grid max-md:grid-rows-2 md:grid-cols-2 gap-5"
					>
						{
							user.uid
							&& (
								<a
									href={IRoutes.booking}
									className={`flex-grow flex justify-center items-center 
								bg-slate-700 hover:text-[#ff722c] hover:bg-white
								${CStyles.transition_300}
								max-h-16 text-center text-white text-lg font-semibold`
									}
								>
									Book Now
								</a>
							)}
						<a
							target="blank"
							href={IAssetURLs.appLink}
							className={`flex-grow flex justify-center items-center 
								bg-[#ff722c] hover:text-[#ff722c] hover:bg-white
								transition duration-300 ease-out
								max-h-16 text-center text-white text-lg font-semibold`
							}
						>
							Download our App
						</a>
					</div>
				</div>
				<div
					className="max-md:hidden md:col-span-1"
				/>
			</div>
		</section>
	);
}