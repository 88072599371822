import React from "react";
import { Card, MiniCard } from "../../../components/cards";
import { SectionHeader } from "../../../components/section_header";

export default function NewsSection(params) {
	return (
		<div id="News" className="w-full h-fit sm:h-[200vh] md:h-fit bg-slate-700">
			<div
				className="w-full h-full container mx-auto py-5 px-10 max-md:px-5"
			>
				<SectionHeader title={'News'} />
				<div
					className="w-full py-5 flex flex-col gap-5"
				>
					{/* <h2 className="text-xl font-bold tracking-tight text-blue-200 sm:text-2xl capitalize">trending</h2> */}

					<div
						className="w-full h-full"
					>
						<Card />
					</div>

					<div className="w-full h-40 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
						<MiniCard />
						<MiniCard />
						<MiniCard />
						<MiniCard />
					</div>
				</div>
			</div>
		</div>
	);
}
