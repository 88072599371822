import React from "react";
import { TileAd } from "../../../components/adContainer";
import { Ad } from "../../../models/adModel";

const AboutUsSection = () => {
	const appName = "Zed Tour";
	const text = [
		`Welcome to ${appName}, your go-to app for exploring the beauty and wonders of Zambia. Our mission is to provide a seamless and immersive experience for travelers, bringing them closer to the rich culture, diverse landscapes, and unique attractions that Zambia has to offer.`,
		`At ${appName}, we believe in the power of travel to create
    meaningful connections and lasting memories. Whether you're an
    adventure seeker, a nature enthusiast, or a cultural explorer, our
    app is designed to cater to your interests and guide you through
    an unforgettable journey across Zambia.`,
		`Join us on this exciting journey of discovery, and let ${appName} 
    be your trusted companion as you embark on unforgettable
    adventures in the heart of Africa.`
	]

	return (
		<section id='aboutus' className="w-full h-fit sm:h-[200vh] md:h-fit bg-slate-800">
			<div
				className="w-full h-full container mx-auto py-5 px-6 max-md:px-5"
			>
				{/* <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          About Us
        </h2> */}
				<div
					className="grid max-md:grid-cols-1 text-center max-md:text-justify text-lg max-sm:text-xs 
					text-white grid-cols-2 max-md:gap-8 p-4 w-full"
				>
					{
						text.map((text, index) => {
							return index % 2 === 0 ?
								(
									<>
										<div
											key={index}
											className="w-full min-h-60 flex items-center justify-center bg-slate-900 shadow-md p-10"
										>
											<p
												className=""
											>
												{text}
											</p>
										</div>
										<TileAd />
									</>
								) :
								(
									<>
										<TileAd />
										<div
											key={index}
											className="w-full min-h-60 flex items-center justify-center bg-slate-900 shadow-md p-10"
										>
											<p
												className=""
											>
												{text}
											</p>
										</div>
									</>
								)
						})
					}
				</div>
			</div>
		</section>

	);
};

export default AboutUsSection;
