class Distress {
	constructor({
		userid,
		username,
		phone,
		geoLocation
	}) {
		this.type = 'DISTRESS_SIGNAL'
		this.userid = userid
		this.username = username || null
		this.phone = phone || null
		this.geoLocation = geoLocation
	}
}

export { Distress }